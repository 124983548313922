import { Card, CardContent, Grid } from '@material-ui/core'
import {
  DataFlattener,
  components,
} from 'cng-web-lib'

import LogisticsAdminTranslationText from '../LogisticsAdminTranslationText'
import React from 'react'
import makeValidationSchema from './MakeValidationSchema'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
      CngSwitchField,
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  name: "",
  pattern: "",
  lastKey: 0,
  minValue: 0,
  maxValue: 0,
  incrementBy: 0,
  cycle: false,
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  const translatedTextsObject = LogisticsAdminTranslationText()

	return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.sequence} />
      <CardContent>
        <Grid container spacing={3}>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.name}>
            <CngTextField
              name="name"
              label={translatedTextsObject.name}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.pattern}>
            <CngTextField
              name="pattern"
              label={translatedTextsObject.pattern}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.lastKey}>
            <CngTextField
              name="lastKey"
              type="number"
              label={translatedTextsObject.lastKey}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.minValue}>
            <CngTextField
              name="minValue"
              type="number"
              label={translatedTextsObject.minValue}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.maxValue}>
            <CngTextField
              name="maxValue"
              type="number"
              label={translatedTextsObject.maxValue}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.incrementBy}>
            <CngTextField
              name="incrementBy"
              type="number"
              label={translatedTextsObject.incrementBy}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.cycle}>
            <CngSwitchField
              name="cycle"
              label={translatedTextsObject.cycle}
              disabled={disabled}
            />
          </CngGridItem>
        </Grid>
      </CardContent>
    </Card>
	)
}

function toClientDataFormat(serverData) {
  let localData = DataFlattener.parse(serverData);
  return localData;
}

function toServerDataFormat(localData) {
  return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
