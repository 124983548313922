import React from 'react';

const PublishModal = ({ isDisplay, displayConfirmModal, toggleDisplayConfirmModal, onConfirmPublish, data }) => {
    return (
        <>
            {isDisplay &&
                <div className={`modal ${displayConfirmModal ? 'display-modal' : ''}`} id="publishConfirmationModal" tabIndex="-1" role="dialog" aria-labelledby="publishConfirmationTitle">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="publishConfirmationTitle">Confirm Microsite Path</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => toggleDisplayConfirmModal()}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>Please confirm that you wish to publish your company's microsite site with the path <b className="pub-path">{data.partyDetails.micrositePath}</b>.</p>
                                <p>You will not be able to change the unique path after this step.</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-secondary hv-btn" onClick={() => toggleDisplayConfirmModal()}>Cancel</button>
                                <button type="button" onClick={() => onConfirmPublish()} id="btnPublishFinal" className="btn btn-outline-primary hv-btn hv-btn-outline-primary">Publish</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default PublishModal
