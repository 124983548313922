import Namespace from 'src/constants/locale/Namespace'
import { constants, Yup } from 'cng-web-lib'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  return Yup.object({
    active: Yup.boolean(),
    userId: Yup.string().required(requiredMessage).nullable(),
    validityStartDate: Yup.date().validFormat("YYYY-MM-DD").typeError(dateTypeErrorMessage).required(requiredMessage).nullable(),
    validityEndDate:  Yup.date().validFormat("YYYY-MM-DD").typeError(dateTypeErrorMessage).required(requiredMessage).nullable()
    .min(Yup.ref('validityStartDate'), "Validity End Date can't be before Validity Start Date"),
  })
}

export default makeValidationSchema
