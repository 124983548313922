import { getMenuPrivileges } from 'src/store/intelligent-advisory'
import pathMap from 'src/paths/PathMap_CalistaPartyCompanyAdmin'

const menuPrivileges = getMenuPrivileges();

export const checkMenuPrivileges = () => {
    let isAdmin = false;
    for (let mod of menuPrivileges) {
        for (let menu of mod.menus) {
            if (!menu.menuUrl.startsWith(pathMap.MODULE_BASE)) {
                break;
            } else {
                if (menu.menuUrl == pathMap.MODULE_BASE) {
                    isAdmin = true;
                    return isAdmin;
                }
            }
        }
    }
}