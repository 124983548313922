import { MenuItem, Select } from '@material-ui/core';
import React from 'react';

const ChangeThemeDropdown = ({ isDisplay, THEMES, theme, changeTheme }) => {
    return (
        <>
            {isDisplay &&
                <div id="inputAppTheme" className="form-control hv-form-control form-control-select nav-list-item w-90px theme-select-dd theme-control">
                    <Select
                        disableUnderline={true}
                        value={theme}
                        onChange={changeTheme}
                    >
                        {THEMES.map((value, i) => (
                            <MenuItem value={value} key={i}>{value}</MenuItem>
                        ))}
                    </Select>
                </div>
            }
        </>
    );
}

export default ChangeThemeDropdown;