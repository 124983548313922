import { Snackbar } from '@material-ui/core'
import { components, constants, SendRequest, useServices } from 'cng-web-lib'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import pathMap from 'src/paths/PathMap_CalistaPartyCompanyAdmin'
import { getPartyID } from 'src/store/intelligent-advisory'
import CngBackdrop from 'src/views/vesselschedule/searchschedule/cngcomponent/CngBackDrop'
import MicrositeLayoutComponent from './components/MicrositeLayoutComponent'
import CalistaPartyCompanyInfoApiUrls from 'src/apiUrls/CalistaPartyCompanyInfoApiUrls'
import MicrositeApiUrls from 'src/apiUrls/MicrositeApiUrls'
import { checkMenuPrivileges } from './components/micrositeUtils'

const {
    CngGridItem,
} = components
const { AxiosMethod } = constants

const MicrositePublishedView = () => {
    const [data, setData] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [isBelongToParty, setIsBelongToParty] = useState(false);
    const [loading, setLoading] = useState(false);
    const [snackBarMsg, setSnackBarMsg] = useState("Changes saved successfully!");
    const location = useLocation();
    const history = useHistory();
    const { updateRecord } = useServices();
    const sendRequest = new SendRequest();
    const partyId = getPartyID();
    const editProfileUrl = pathMap.MODULE_BASE + "/edit/" + partyId;
    const websitePathArr = location.pathname.split("/");

    let websitePath;
    if (websitePathArr[websitePathArr.length - 1].length !== 0) {
        websitePath = websitePathArr[websitePathArr.length - 1];
    } else if (websitePathArr[websitePathArr.length - 2].length !== 0) {
        websitePath = websitePathArr[websitePathArr.length - 2];
    } else {
        websitePath = null;
    }
    useEffect(() => {
        setLoading(true);
        setIsAdmin(checkMenuPrivileges());
        if (websitePath != null) {
            sendRequest.execute(AxiosMethod.GET, `${MicrositeApiUrls.GET_WEBSITE}/${websitePath}`,
                {},
                (body) => {
                    setLoading(false);
                    const responseData = body.data;
                    if (partyId == responseData.partyDetails.id) {
                        setIsBelongToParty(true);
                    }
                    const countriesWithNoAddress = responseData.presentInCountryList.filter(country => country !== responseData.addressCountry);
                    responseData.countriesWithNoAddress = countriesWithNoAddress;
                    responseData.countryAddressArr = [responseData.addressLine01, responseData.addressLine02, responseData.addressLine03, responseData.city, responseData.state];
                    setData(responseData);
                },
                () => {
                    setLoading(false);
                    history.push("/404");
                }
            )
        }
    }, [websitePath])


    const saveThemeChanges = (theme) => {
        setLoading(true);
        const partyDetails = { ...data.partyDetails, isMicrositePublished: data.partyDetails.isMicrositePublished, micrositeTheme: theme };
        const onSuccess = () => {
            setLoading(false);
            setSnackBarMsg("Changes saved successfully!");
            setSnackbarOpen(true);
        }
        const onError = () => {
            setLoading(false);
            setSnackBarMsg("Something went wrong!");
            setSnackbarOpen(true);
        }

        updateRecord.execute(
            `${CalistaPartyCompanyInfoApiUrls.UPDATE_MICROSITE_PREVIEW}`,
            partyDetails,
            onSuccess,
            onError
        );
    }

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    }

    return (
        <>
            <CngGridItem xs={12} sm={9} shouldHide={loading ? false : true}>
                <CngBackdrop loading={loading} />
            </CngGridItem>
            {data &&
                <MicrositeLayoutComponent
                    data={data}
                    preview={false}
                    disabled={false}
                    isPartyAdmin={isBelongToParty && isAdmin}
                    editProfileUrl={editProfileUrl}
                    saveThemeChanges={saveThemeChanges}
                />
            }
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                message={snackBarMsg}
            />
        </>
    );
}
export default MicrositePublishedView;
