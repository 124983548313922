import Namespace from 'src/constants/locale/Namespace'
import { Yup } from 'cng-web-lib'
import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  return Yup.object({
    active: Yup.boolean(),
    containerIsoType: Yup.string(),
    contHeight: Yup.string(),
    contHeightDesc: Yup.string(),
    contHeightValue: Yup.string(),
    contSize: Yup.string(),
    contType: Yup.string(),
    contTypeDesc: Yup.string(),
    haulioContSizeType: Yup.string(),
    isoCode: Yup.string(),
    containerCode: Yup.string(),
  })
}

export default makeValidationSchema
