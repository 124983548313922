import RMUCarousel from 'react-material-ui-carousel'
import React from 'react';

const AwardSection = ({ data, refsArray }) => {
    return (
        <>
            {data.visibility.hasAwards &&
                <div ref={ref => refsArray.current[2] = ref} id="awardsAndAccredit" name="awardsAndAccredit" className="hv-section">
                    <div className="container-fluid">
                        <div className="row no-gutters w-100">
                            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-1 col-1"></div>
                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                                <section className="hv-section__inner-wrapper">
                                    <span className="hv-mobile-only w-100">
                                        <i className="section-icon icon-awards-accredit"></i>
                                    </span>
                                    <h1 className="section-primary-title text-center">
                                        Awards & Accreditations
                                    </h1>

                                    <article className="section-carousel-wrapper carousel slide">
                                        <div className="carousel-inner">
                                            <>
                                                {
                                                    data.partyAwardDTOList.length <= 1 &&
                                                    <>
                                                        {
                                                            data.partyAwardDTOList.map((award, i) =>
                                                                <div className='carousel-item active' key={i}>
                                                                    <div className="card hv-card text-center">
                                                                        <div className="card-header">
                                                                            <h4 className="card-primary-title">
                                                                                {award.title}
                                                                            </h4>
                                                                            {award.supportingDocument !== null && <img src={award.supportingDocument} className="award-doc" />}

                                                                        </div>
                                                                        <div className="card-body">
                                                                            <p className="card-text shrink-text" data-length="300">
                                                                                {award.description}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )

                                                        }
                                                    </>
                                                }

                                                {
                                                    data.partyAwardDTOList.length > 1 &&
                                                    <RMUCarousel
                                                        autoPlay={false}
                                                        indicatorContainerProps={{
                                                            style: {
                                                                display: 'none'
                                                            }
                                                        }}
                                                        navButtonsAlwaysVisible={data.partyAwardDTOList.length > 1}
                                                    >
                                                        {
                                                            data.partyAwardDTOList.map((award, i) =>
                                                                <div className='carousel-item active' key={i}>
                                                                    <div className="card hv-card text-center">
                                                                        <div className="card-header">
                                                                            <h4 className="card-primary-title">
                                                                                {award.title}
                                                                            </h4>
                                                                            {award.supportingDocument !== null && <img src={award.supportingDocument} className="award-doc" />}

                                                                        </div>
                                                                        <div className="card-body">
                                                                            <p className="card-text shrink-text" data-length="300">
                                                                                {award.description}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </RMUCarousel>
                                                }
                                            </>

                                        </div>
                                    </article>
                                </section>
                            </div>
                            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-1 col-1"></div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default AwardSection
