import { Snackbar } from '@material-ui/core'
import { useServices, components } from 'cng-web-lib'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { LayoutContext } from 'src/App'
import pathMap from 'src/paths/pathMap'
import MicrositeLayoutComponent from 'src/views/microsite/components/MicrositeLayoutComponent'
import CngBackdrop from 'src/views/vesselschedule/searchschedule/cngcomponent/CngBackDrop'
import CalistaPartyCompanyInfoApiUrls from 'src/apiUrls/CalistaPartyCompanyInfoApiUrls'

const {
    CngGridItem,
} = components

const MicrositePreview = () => {
    const [data, setData] = useState(null);
    const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackBarMsg, setSnackBarMsg] = useState("Changes saved successfully!");
    const [loading, setLoading] = useState(false);
    const { fetchRecords, updateRecord } = useServices();
    const { setDisplayLayoutWithNoSidebar } = useContext(LayoutContext)
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        setLoading(true);
        setDisplayLayoutWithNoSidebar(true);

        const onSuccess = (response) => {
            setLoading(false);
            const responseData = response;
            if (responseData.partyDetails.isMicrositePublished) {
                history.push("/404");
            }
            const countriesWithNoAddress = responseData.presentInCountryList.filter(country => country !== responseData.addressCountry);
            responseData.countriesWithNoAddress = countriesWithNoAddress;
            responseData.countryAddressArr = [responseData.addressLine01, responseData.addressLine02, responseData.addressLine03, responseData.city, responseData.state];
            setData(responseData);
        }

        const onError = () => {
            setLoading(false);
            history.push("/404");
        }

        const onComplete = (done) => { console.log("Finally:[" + JSON.stringify(done) + "]") }

        if (location.state && location.state.id) {
            fetchRecords.execute(
                `${CalistaPartyCompanyInfoApiUrls.GET_MICROSITE_PREVIEW}`,
                {
                    customData: {
                        id: location.state.id
                    }
                },
                onSuccess,
                onError,
                onComplete
            );
        } else {
            history.push("/404");
        }
        return () => setDisplayLayoutWithNoSidebar(false);

    }, [location.state])


    const toggleDisplayConfirmModal = () => {
        setDisplayConfirmModal(!displayConfirmModal);
    }

    const saveThemeChanges = (theme) => {
        setLoading(true);
        const partyDetails = { ...data.partyDetails, isMicrositePublished: data.partyDetails.isMicrositePublished, micrositeTheme: theme };
        const onSuccess = () => {
            setLoading(false);
            setSnackBarMsg("Changes saved successfully!");
            setSnackbarOpen(true);
        }
        const onError = () => {
            setLoading(false);
            setSnackBarMsg("Something went wrong!");
            setSnackbarOpen(true);
        }

        updateRecord.execute(
            `${CalistaPartyCompanyInfoApiUrls.UPDATE_MICROSITE_PREVIEW}`,
            partyDetails,
            onSuccess,
            onError
        );
    }

    const onConfirmPublish = (theme) => {
        setLoading(true);

        const onSuccess = () => {
            setLoading(false);
            history.push(`${pathMap.PARTY_MICROSITE_VIEW_BASE}/${data.partyDetails.micrositePath}`);
        }
        const onError = () => {
            setLoading(false);
            setSnackBarMsg("Something went wrong!");
            setSnackbarOpen(true);
        }
        const partyDetails = { ...data.partyDetails, isMicrositePublished: true, micrositeTheme: theme };
        updateRecord.execute(
            `${CalistaPartyCompanyInfoApiUrls.UPDATE_MICROSITE_PREVIEW}`,
            partyDetails,
            onSuccess,
            onError
        );
    }

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    }

    return (
        <>
           <CngGridItem xs={12} sm={9} shouldHide={loading ? false : true}>
                <CngBackdrop loading={loading} />
            </CngGridItem>
            {data &&
                <MicrositeLayoutComponent
                    data={data}
                    preview={true}
                    disabled={true}
                    displayConfirmModal={displayConfirmModal}
                    setDisplayConfirmModal={setDisplayConfirmModal}
                    toggleDisplayConfirmModal={toggleDisplayConfirmModal}
                    saveThemeChanges={saveThemeChanges}
                    onConfirmPublish={onConfirmPublish}
                />
            }
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                message={snackBarMsg}
            />
        </>
    );
}


export default MicrositePreview;
