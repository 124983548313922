import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import pathMap from 'src/paths/pathMap';
import React from 'react';
import { CNG_BASE_URL } from './ContactUsConstants';
const PUBLIC_URL = process.env.PUBLIC_URL

const ContactUsDetailsSection = ({ data }) => {

    const displayAddress = (arrValues) => {
        let html = "";
        arrValues.forEach((value) => {
            if (value) {
                html += value + "<br/>";
            }
        })
        if (data.addressCountry) {
            html += data.addressCountry;
            if (data.postalCode) {
                html += " " + data.postalCode;
            }
        }
        return html;
    }

    return (
        <article className="section-article-info contact-info">
            <span className="hv-mobile-only w-100">
                <i className="section-icon icon-contact-us"></i>
            </span>
            <h1 className="section-primary-title w-100 flex-content-center hv-mobile-only">Contact Us</h1>
            {data.partyDetails.contactPhone &&
                <div className="address icon icon-mobile">
                    <label>
                        {data.partyDetails.contactPhone}
                    </label>
                </div>
            }
            {data.partyDetails.contactEmail &&
                <div className="address icon icon-email">
                    <label><a href="mailto:${data.partyDetails.contactEmail}">
                        {data.partyDetails.contactEmail}
                    </a></label>
                </div>
            }
            {data.addressCountry &&
                <div className="address icon icon-location">
                    <span className="address-group">
                        <label>{parse(displayAddress(data.countryAddressArr))}</label>
                    </span>
                </div>
            }
            {data.countriesWithNoAddress.map((country, i) =>
                <div className="address icon icon-location" key={i}>
                    <span className="address-group">
                        <label>{country}</label>
                    </span>
                </div>
            )}

            <div className="address icon icon-web">
                {
                    data.partyDetails.demoMicrositePath ?
                        <label>
                            <Link to={`${pathMap.PARTY_MICROSITE_DEMO}`} target="_blank" rel='noopener noreferrer'>
                                {`${CNG_BASE_URL}${pathMap.PARTY_MICROSITE_DEMO}`}
                            </Link>
                        </label>
                        :
                        <label>
                            <Link to={`${pathMap.PARTY_MICROSITE_VIEW_BASE}/${data.partyDetails.micrositePath}`} target="_blank" rel='noopener noreferrer'>
                                {`${CNG_BASE_URL}${pathMap.PARTY_MICROSITE_VIEW_BASE}/${data.partyDetails.micrositePath}`}
                            </Link>
                        </label>
                }

            </div>
        </article>
    );
}
export default ContactUsDetailsSection;
