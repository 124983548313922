import React, { useState } from 'react'

import CloseDialogIconButton from 'src/components/iconbutton/CloseDialogIconButton.js'
import NmotTranslationText from 'src/views/nmot/shared/NmotTranslationText'
import AddNmotUserDialogContext from './AddNmotUserDialogContext.js'
import AddNmotUserDialogForm from './AddNmotUserDialogForm.js'
import Paper from '@material-ui/core/Paper'
import { components } from 'cng-web-lib'

const { CngDialog } = components

function AddNmotUserDialog({
  isDialogOpen,
  closeDialog,
  showNotification,
  validUserList,
  cngTableRef
}) {
  const [isSubmitting, setSubmitting] = useState(false)
  const translatedTextsObject = NmotTranslationText()

  return (
    <AddNmotUserDialogContext.Provider
      value={{
        closeDialog,
        showNotification,
        validUserList,
        form: {
          isSubmitting,
          setSubmitting
        },
        cngTableRef
      }}
    >
      <CngDialog
        customDialogContent={
          <Paper>
            <div style={{ overflow: 'hidden' }}>
              <AddNmotUserDialogForm />
            </div>
          </Paper>
        }
        dialogTitle={
          <>
            {translatedTextsObject.addNmotUserTitle}
            <CloseDialogIconButton
              onClick={closeDialog}
              disabled={isSubmitting}
            />
          </>
        }
        open={isDialogOpen}
        fullWidth
        maxWidth='sm'
      />
    </AddNmotUserDialogContext.Provider>
  )
}

export default AddNmotUserDialog
