import {
  Yup
} from 'cng-web-lib'
import * as ValidationRegex from 'src/views/nmot/shared/ValidationRegex'

function makeValidationSchema() {

  const emojiNotAllowedMsg = "Emoji characters are not allowed"
  const specialCharNotAllowedMsg = "Invalid input. `~$^+=|<> special characters are not allowed"
  const spacesNotAllowedMsg = "Invalid input. White spaces not allowed"
  const invalidContMsg = "Invalid container number. Container number should be in ISO6346 format. Eg: CSQU3054383"
  const requiredMsg = "Required."

  return Yup.object({

    contNo: Yup.string().required(requiredMsg)
    .test("contNo", invalidContMsg, (value) => ValidationRegex.validContNo.test(value))
    .test("contNo", emojiNotAllowedMsg, (value) => ValidationRegex.emojiRegx.test(value))
    .test("contNo", specialCharNotAllowedMsg, (value) => ValidationRegex.specialCharNotAllowedRegx.test(value))
    .test("contNo", spacesNotAllowedMsg, (value) => ValidationRegex.spaceNotAllowedRegex.test(value))
    .nullable(),

    nmot: Yup.string().required(requiredMsg).nullable(),

    nextCarrier: Yup.string()
    .test("nextCarrier", emojiNotAllowedMsg, (value) => ValidationRegex.emojiRegx.test(value))
    .test("nextCarrier", specialCharNotAllowedMsg, (value) => ValidationRegex.specialCharNotAllowedRegx.test(value))
    .nullable(),

    goodDesc: Yup.string()
    .test("goodDesc", emojiNotAllowedMsg, (value) => ValidationRegex.emojiRegx.test(value))
    .test("goodDesc", specialCharNotAllowedMsg, (value) => ValidationRegex.specialCharNotAllowedRegx.test(value))
    .nullable(),

    blNo: Yup.string()
    .test("blNo", emojiNotAllowedMsg, (value) => ValidationRegex.emojiRegx.test(value))
    .test("blNo", specialCharNotAllowedMsg, (value) => ValidationRegex.specialCharNotAllowedRegx.test(value))
    .test("blNo", spacesNotAllowedMsg, (value) => ValidationRegex.spaceNotAllowedRegex.test(value))
    .nullable()

  })
}

export default makeValidationSchema
