import { components, constants, useServices } from 'cng-web-lib';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import ContactUsDetailsSection from './ContactUsDetailsSection';
import FormFields from './ContactUs_FormProperties';
import { TEXT, FIELDS_MAX_LENGTH } from './ContactUsConstants';

const {
    FormState,
} = constants

const {
    form: {
        CngForm
    },
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
    firstName: '',
    lastName: '',
    requesterEmail: '',
    contactNumberCountryCode: '',
    contactNumber: '',
    company: '',
    companyCountryCode: '',
    subject: '',
    message: ''
})
const phoneRegx = /^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/

const formikProp = {
    initialValues: DEFAULT_INITIAL_VALUES,
    makeValidationSchema: () => {
        return Yup.object({
            firstName: Yup.string().required("First Name is required").max(FIELDS_MAX_LENGTH.FIRST_NAME, TEXT.EXCEED_CHAR_MSG_OF(FIELDS_MAX_LENGTH.FIRST_NAME)),
            lastName: Yup.string().required("Last Name is required").max(FIELDS_MAX_LENGTH.LAST_NAME, TEXT.EXCEED_CHAR_MSG_OF(FIELDS_MAX_LENGTH.LAST_NAME)),
            requesterEmail: Yup.string().required("Email is required").email("Invalid email").max(FIELDS_MAX_LENGTH.EMAIL, TEXT.EXCEED_CHAR_MSG_OF(FIELDS_MAX_LENGTH.EMAIL)),
            contactNumber: Yup.string().matches(phoneRegx, "Invalid phone number").nullable().max(FIELDS_MAX_LENGTH.PHONE_NUM, TEXT.EXCEED_CHAR_MSG_OF(FIELDS_MAX_LENGTH.PHONE_NUM)),
            contactNumberCountryCode: Yup.string().when('contactNumber', {
                is: (number) => number && number.length > 0,
                then: Yup.string().required("Please select contact number country area code"),
                otherwise: Yup.string().nullable()
            }),
            company: Yup.string().nullable().max(FIELDS_MAX_LENGTH.COMPANY, TEXT.EXCEED_CHAR_MSG_OF(FIELDS_MAX_LENGTH.COMPANY)),
            companyCountryCode: Yup.string().when('company', {
                is: (company) => company && company.length > 0,
                then: Yup.string().required("Please select country location of company"),
                otherwise: Yup.string().nullable()
            }),
            subject: Yup.string().required("Subject is required").max(FIELDS_MAX_LENGTH.SUBJECT, TEXT.EXCEED_CHAR_MSG_OF(FIELDS_MAX_LENGTH.SUBJECT)),
            message: Yup.string().required("Message is required").max(FIELDS_MAX_LENGTH.MESSAGE, TEXT.EXCEED_CHAR_MSG)
        })
    }
}

const ContactUsSection = ({ data, refsArray, disabled, isUserLoggedIn }) => {
    const [userDetails, setUserDetails] = useState(DEFAULT_INITIAL_VALUES);
    const { fetchRecords } = useServices();

    useEffect(() => {
        if (isUserLoggedIn) {
            getUserProfileDetails();
        }
    }, [])

    const getUserProfileDetails = () => {
        const onSuccess = (response) => {
            setUserDetails({
                ...userDetails,
                firstName: response.name1,
                lastName: response.name3,
                requesterEmail: response.email || '',
                contactNumber: response.mobileNumber || ''
            })
        }
        fetchRecords.execute(
            `${process.env.REACT_APP_USER_ORIGIN_URL}/tpr-auth/user-profile/get`,
            {},
            onSuccess
        );
    }

    return (
        <>
            {data.visibility.hasContactUs &&
                <div ref={ref => refsArray.current[5] = ref} id="contactUs" name="contactUs" className="hv-section">
                    <div className="container-fluid">
                        <div className="row no-gutters w-100">
                            <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1"></div>
                            <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12">
                                <section className="hv-section__inner-wrapper">
                                    <>
                                        <article className="section-article-form contact-us-form pos-relative" id="contactUsFormWrapper">
                                            <h1 className="section-primary-title hv-desktop-only">
                                                Contact Us
                                            </h1>
                                            <h1 className="section-secondary-title flex-content-center no-m-y-axis mt-30 hv-mobile-only">Leave us a message</h1>

                                            <CngForm
                                                hookFormProps={{
                                                    initialValues: userDetails,
                                                    makeValidationSchema: formikProp.makeValidationSchema
                                                }}
                                                renderBodySection={() => {
                                                    return (
                                                        <>
                                                            <FormFields data={data} disabled={disabled} />
                                                        </>
                                                    )
                                                }}
                                                renderButtonSection={() =>
                                                    <></>
                                                }
                                                formState={FormState.COMPLETED}
                                            />
                                        </article>
                                        <span className="vertical-divider-line"></span>
                                        <ContactUsDetailsSection data={data} />
                                    </>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default ContactUsSection

