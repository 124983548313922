import React, {useEffect, useState} from 'react'
import { components, useServices } from 'cng-web-lib'

import FormProperties from './FormProperties'
import { Grid } from '@material-ui/core'
import SequenceApiUrls from 'src/apiUrls/SequenceApiUrls'
import pathMap from 'src/paths/PathMap_LogisticsAdmin'
import { useLocation } from 'react-router-dom'

const {
  form: { CngAddForm }
} = components

function AddPage({ history, showNotification }) {

  const {fetchRecords} = useServices()
  const [queryResult, setQueryResult] = useState("")
  const [loading, setLoading] = useState(true)
  const location = useLocation()

  useEffect(() => {
    setLoading(true)
    const queryParams = new URLSearchParams(location.search)

    fetchRecords.execute(
      `${process.env.REACT_APP_CLOG_SHARED_SERVICE_ORIGIN_URL}/module/get`,//string 
      {
          data: {
              id: queryParams.get('moduleId'),
          }
      },
      (data) => {
          let queryReturn = data.content[0].moduleUrl

          setQueryResult(`${process.env[queryReturn]}`)

          setLoading(false)
      },
      (error) => {
        console.log("error")
      })

    
    },[])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngAddForm
          fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
          history={history}
          showNotification={showNotification}
          bodySection={
            <FormProperties.Fields
              disabled={false}
              showNotification={showNotification}
            />
          }
          formikProps={FormProperties.formikProps}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          create={{
            url: `${queryResult}/sequence/hdr/create`,
            successRedirect: pathMap.SEQUENCE_LIST_VIEW
          }}
        />
      </Grid>
    </Grid>
  )
}

export default AddPage
