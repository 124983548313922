import RMUCarousel from 'react-material-ui-carousel'
import DefaultProfileLogo from 'src/assets/img/microsite/user-profile-dummy.png';
import React from 'react';

const MoreAboutUsSection = ({ data, refsArray }) => {
    return (
        <>
            {data.visibility.hasAboutUs &&
                <div ref={ref => refsArray.current[3] = ref} id="moreAboutUs" name="moreAboutUs" className="hv-section">
                    <div className="container-fluid">
                        <div className="row no-gutters w-100">
                            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-1 col-1"></div>
                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                                <section className="hv-section__inner-wrapper">
                                    <span className="hv-mobile-only w-100">
                                        <i className="section-icon icon-about-us"></i>
                                    </span>
                                    <h1 className="section-primary-title">
                                        More About Us
                                    </h1>
                                    <p className="section-primary-description shrink-text" data-length="500">
                                        {data.partyDetails.partyIntroduction}
                                    </p>

                                    {/* company media */}
                                    <article className="section-video-wrapper">
                                        <div className="carousel-inner">

                                            {data.partyMediaDTOList.length <= 1 &&
                                                <>
                                                    {
                                                        data.partyMediaDTOList.map((media, i) =>
                                                            <div className="card hv-video-card" key={i}>
                                                                <div className="card-image">
                                                                    <div className="embed-responsive embed-responsive-16by9">
                                                                        <img src={media.media} className="embed-responsive-item" />

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </>
                                            }
                                            {
                                                data.partyMediaDTOList.length > 1 &&
                                                <RMUCarousel
                                                    autoPlay={true}
                                                    indicatorContainerProps={{
                                                        style: {
                                                            display: 'none'
                                                        }
                                                    }}
                                                    navButtonsAlwaysVisible={true}
                                                >
                                                    {
                                                        data.partyMediaDTOList.map((media, i) =>
                                                            <div className="card-media" key={i}>
                                                                <div className="card hv-video-card">
                                                                    <div className="card-image">
                                                                        <div className="embed-responsive embed-responsive-16by9">
                                                                            <img src={media.media} className="embed-responsive-item" />

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </RMUCarousel>
                                            }
                                        </div>
                                    </article>
                                    <span className="horizontal-divider-line hv-mobile-only"></span>

                                    {data.partyManagementUserDTOList.length > 0 &&
                                        <>
                                            <h1 className="section-secondary-title">
                                                Management Team
                                            </h1>
                                            <article className="section-carousel-wrapper section-flex-wrap">
                                                {data.partyManagementUserDTOList.map((user, i) => {
                                                    return (
                                                        <div className="card hv-card hv-mgmt-team text-center has-focus" key={i}>
                                                            <div className="card-body pos-relative">
                                                                <div className="card-header-band"></div>
                                                                {user.profileLink && <a href={`https://www.linkedin.com/in/${user.profileLink}`} target="_blank" className="stretched-link"></a>}
                                                                <>
                                                                    <div className="profile-pic-wrapper pos-relative">
                                                                        <img src={user.profilePhoto ? user.profilePhoto : DefaultProfileLogo} className="mgmt-member-photo pos-relative" />
                                                                        {
                                                                            user.profileLink && <i className="icon icon-linkedin"></i>
                                                                        }
                                                                    </div>
                                                                    <h3 className="mgmt-member-name">
                                                                        {user.fullName}
                                                                    </h3>
                                                                    <h3 className="mgmt-member-title">
                                                                        {user.role}
                                                                    </h3>
                                                                </>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </article>
                                        </>
                                    }
                                </section>
                            </div>
                            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-1 col-1"></div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default MoreAboutUsSection
