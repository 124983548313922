import { Card, CardContent, Grid } from '@material-ui/core'

import ContainerTypeApiUrls from 'src/apiUrls/ContainerTypeApiUrls'
import LogisticsAdminTranslationText from '../LogisticsAdminTranslationText'
import React from 'react'
import {
  components
} from 'cng-web-lib'

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    useDefaultNotification
  }
} = components

function TablePage(props) {
  const {
    location: { pathname },
    showNotification
  } = props

  const notification = useDefaultNotification(showNotification)

  const translatedTextsObject = LogisticsAdminTranslationText()

  const columns = [
    {
      field: "active",
      title: translatedTextsObject.active,
      type: "boolean",
    },
    {
      field: "containerIsoType",
      title: translatedTextsObject.containerIsoType,
    },
    {
      field: "contHeight",
      title: translatedTextsObject.contHeight,
    },
    {
      field: "contHeightDesc",
      title: translatedTextsObject.contHeightDesc,
    },
    {
      field: "contHeightValue",
      title: translatedTextsObject.contHeightValue,
    },
    {
      field: "contSize",
      title: translatedTextsObject.contSize,
    },
    {
      field: "contType",
      title: translatedTextsObject.contType,
    },
    {
      field: "contTypeDesc",
      title: translatedTextsObject.contTypeDesc,
    },
    {
      field: "haulioContSizeType",
      title: translatedTextsObject.haulioContSizeType,
    },
    {
      field: "isoCode",
      title: translatedTextsObject.isoCode,
    },
    {
      field: "containerCode",
      title: translatedTextsObject.containerCode,
    }
  ]

  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.containerTypeTableTitle} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              addRoute={`${pathname}/add`}
              columns={columns}
              del={{ url: ContainerTypeApiUrls.DELETE }}
              editRoute={`${pathname}/edit`}
              exportData={{ url: ContainerTypeApiUrls.EXPORT }}
              fetch={{ url: ContainerTypeApiUrls.GET }}
              idAccessor="id"
              notification={notification}
              //tableConfigurationCode="REPLACE_WITH_CORE_TABLE_CONFIG_CODE"
              viewRoute={`${pathname}/view`}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default TablePage
