
import { BasePage as CngBasePage } from 'cng-web-lib'

import React from 'react'
import TablePage from './TablePage.js'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_LogisticsAdmin'

function WrappedTablePage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <CngBasePage
      renderPage={(showSnackbar) => (
        <TablePage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}




export {
  WrappedTablePage as TablePage
}
