export const TEXT = {
    MAX_CHARACTERS: 'Max. Length:',
    CHARACTERS: 'characters',
    EXCEED_CHAR_MSG: 'Exceeded max. character limit',
    OF: 'of',
    EXCEED_CHAR_MSG_OF: function (characters) {
        return `${this.EXCEED_CHAR_MSG} ${this.OF} ${characters}`
    }
}

export const FIELDS_MAX_LENGTH = {
    FIRST_NAME: 255,
    LAST_NAME: 255,
    EMAIL: 255,
    PHONE_NUM: 50,
    COMPANY: 255,
    SUBJECT: 1000,
    MESSAGE: 2000
}

export const CNG_BASE_URL = `${process.env.REACT_APP_BASE_URL}${process.env.PUBLIC_URL}`
