import { Card, CardContent, Grid } from '@material-ui/core'
import {
  DataFlattener,
  components,
} from 'cng-web-lib'

import LogisticsAdminTranslationText from '../LogisticsAdminTranslationText'
import React from 'react'
import makeValidationSchema from './MakeValidationSchema'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  moduleName: "",
  moduleUrl: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {

  const translatedTextsObject = LogisticsAdminTranslationText()

	return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.module} />
      <CardContent>
        <Grid container spacing={3}>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.moduleName}>
            <CngTextField
              name="moduleName"
              label={translatedTextsObject.moduleNameLabel}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.moduleUrl}>
            <CngTextField
              name="moduleUrl"
              label={translatedTextsObject.moduleUrl}
              disabled={disabled}
            />
          </CngGridItem>
        </Grid>
      </CardContent>
    </Card>
	)
}

function toClientDataFormat(serverData) {
  let localData = DataFlattener.parse(serverData);
  return localData;
}

function toServerDataFormat(localData) {
  return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
