import { Box, Grid } from '@material-ui/core'
import React, {useEffect, useState} from 'react'
import { components, constants, useServices, useTranslation } from 'cng-web-lib'

import LogisticsAdminTranslationText from './LogisticsAdminTranslationText'

const {
  form: {
    adapter: {
      useFormAdapter:{ useField, useFormikContext }
    },
    CngAddForm,
    field: {
      CngTextField,
      CngSelectField
    },
  },
  button: { CngPrimaryButton, CngSecondaryButton },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  moduleUrl: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES }
}

function FormFields({
  history,
  showNotification
}) {

  const translatedTextsObject = LogisticsAdminTranslationText()  
 
  const { submitForm, setFieldValue } = useFormikContext()
  const onClick = () => {
    submitForm()
  }
  return (
    <Box>
    <Grid container spacing={3} justify="center">
      <CngGridItem xs={8} sm={4}>
        <CngSelectField
            name="module"
            type="string"
            label={translatedTextsObject.moduleName}
            fetch={
                {
                    url: `${process.env.REACT_APP_CLOG_SHARED_SERVICE_ORIGIN_URL}/module/get`,
                    textAccessor: "moduleName",
                    valueAccessor: "id"
                }
            }
            //onChange={onModuleChange}
            />
      </CngGridItem>

      <CngPrimaryButton type='submit' style={{width:'20%', height: '50px', alignSelf: 'center'}}>Search</CngPrimaryButton>
    </Grid>
    </Box>
  )
}

function toClientDataFormat(serverData) {
  let localData = serverData;
  return localData;
}

function toServerDataFormat(localData) {
  return localData;
}

const SearchForm = Object.freeze({
  formikProps: FORMIK_PROPS,
  FormFields: FormFields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default SearchForm
