import CalistaLogo from 'src/assets/img/microsite/logo-calista-black.png';
import React from 'react';

const GetsServiceSection = ({refsArray}) => {
    return (
        <div ref={ref => refsArray.current[6] = ref} id="getsServicesUsed" name="getsServicesUsed" className="hv-section">
            <div className="container-fluid">
                <div className="row no-gutters w-100">
                    <div className="col-xl-2 col-lg-2 col-md-2 col-sm-1 col-1"></div>
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-10 col-10">
                        <section className="hv-section__inner-wrapper">
                            <span className="hv-mobile-only w-100">
                                <i className="section-icon icon-gets-services"></i>
                            </span>
                            <h1 className="section-primary-title">GeTS Services Used</h1>

                            <article className="section-carousel-wrapper">
                                <div className="card hv-card">
                                    <div className="card-body text-center">
                                        <div className="service-logo-wrapper">
                                            <img src={CalistaLogo} alt="CALISTA" className="service-logo" />
                                        </div>
                                        <p className="card-text">
                                            CALISTA is a global supply chain platform that brings together key physical, compliance and financial activities of cargo logistics on a digital eco-system.
                                        </p>
                                    </div>
                                </div>
                            </article>
                        </section>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-2 col-sm-1 col-1"></div>
                </div>
            </div>
        </div>
    );
}

export default GetsServiceSection
