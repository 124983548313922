import { Card, CardContent, Grid } from '@material-ui/core'

import CustomUserAccountApiUrls from 'src/apiUrls/CustomUserAccountApiUrls'
import LogisticsAdminTranslationText from '../LogisticsAdminTranslationText'
import React from 'react'
import {
  components
} from 'cng-web-lib'

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    useFetchCustomLookup,
    DateRangeFilter: CngDateRangeFilter,
    useDefaultNotification
  }
} = components

function TablePage(props) {
  const {
    location: { pathname },
    showNotification
  } = props

  const notification = useDefaultNotification(showNotification)

  const translatedTextsObject = LogisticsAdminTranslationText()
  const fetchCustomLookup = useFetchCustomLookup();

  const columns = [
    {
      field: "userId",
      title: translatedTextsObject.userId,
       customLookup: () => {
              return fetchCustomLookup(
                  `${CustomUserAccountApiUrls.GET_USER}`,
                  { },
                  'content',
                  'loginId',
                  'id',
                  (error) => console.error(error)
              )
            }
    },
    {
      field: "validityStartDate",
      title: translatedTextsObject.validityStartDate,
      type: "date",
      filterComponent: CngDateRangeFilter,
    },
    {
      field: "validityEndDate",
      title: translatedTextsObject.validityEndDate,
      type: "date",
      filterComponent: CngDateRangeFilter,
    },
    {
      field: "active",
      title: translatedTextsObject.activeUser,
      type: "boolean",
    }
  ]

  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.customUserAccountTableTitle} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              addRoute={`${pathname}/add`}
              columns={columns}
              del={{ url: CustomUserAccountApiUrls.DELETE }}
              editRoute={`${pathname}/edit`}
              exportData={{ url: CustomUserAccountApiUrls.EXPORT }}
              fetch={{ url: CustomUserAccountApiUrls.GET }}
              idAccessor="id"
              notification={notification}
              //tableConfigurationCode="REPLACE_WITH_CORE_TABLE_CONFIG_CODE"
              viewRoute={`${pathname}/view`}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default TablePage