import { Button, Menu, MenuItem } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';

const EditProfileDropdown = ({ isDisplay, editProfileUrl }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const history = useHistory();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }
    const handleClose = () => {
        setAnchorEl(null);
    };

    const goToEditProfile = () => {
        if (editProfileUrl) {
            history.push(editProfileUrl);
        } else {
            history.goBack();
        }
    }
    return (
        <>
            {isDisplay &&
                <>
                    <span className="menu-divider nav-list-item"></span>
                    <div className="btn-group nav-list-item">
                        <Button
                            id="basic-button"
                            aria-controls="basic-menu"
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                            className="btn dropdown-toggle icon-button-transparent"
                        >
                            <i className="btn-icon btn-icon-32 icon-settings"></i>
                        </Button>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={() => goToEditProfile()}>
                                <i className="label-icon ic-24 icon-edit-profile"></i>
                                Edit Profile
                            </MenuItem>
                        </Menu>
                    </div>
                </>
            }
        </>

    );
}
export default EditProfileDropdown
