import { Box, Card, CardContent, Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import {
  components,
  constants,
  useServices
} from 'cng-web-lib'

import FormProperties from './FormProperties'
import LogisticsAdminTranslationText from '../LogisticsAdminTranslationText'
import Namespace from 'src/constants/locale/Namespace'
import SearchForm from '../SearchForm'
import SequenceApiUrls from 'src/apiUrls/SequenceApiUrls'
import { useHistory } from 'react-router-dom'
import {v4 as uuid} from 'uuid'

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    useDefaultNotification
  },
  form: { CngViewForm }
} = components

function TablePage(props) {
  const {
    location: { pathname },
    showNotification
  } = props

  const notification = useDefaultNotification(showNotification)
  const translatedTextsObject = LogisticsAdminTranslationText()
  const {fetchRecords} = useServices()
  const [searchCriteria, setSearchCriteria] = useState("")
  const [queryResult, setQueryResult] = useState("")
  const [stateKey, setStateKey] = useState(uuid())
  const [loading, setLoading] = useState(true)
  const history = useHistory()

  const columns = [
    {
      field: "name",
      title: translatedTextsObject.name,
    },
    {
      field: "pattern",
      title: translatedTextsObject.pattern,
    },
    {
      field: "lastKey",
      title: translatedTextsObject.lastKey,
      type: "numeric",
    },
    {
      field: "minValue",
      title: translatedTextsObject.minValue,
      type: "numeric",
    },
    {
      field: "maxValue",
      title: translatedTextsObject.maxValue,
      type: "numeric",
    },
    {
      field: "incrementBy",
      title: translatedTextsObject.incrementBy,
      type: "numeric",
    },
    {
      field: "cycle",
      title: translatedTextsObject.cycle,
      type: "boolean",
    }
  ]

  function onSearch(data) {
    setLoading(true)
    setSearchCriteria(data.module)

      fetchRecords.execute(
        `${process.env.REACT_APP_CLOG_SHARED_SERVICE_ORIGIN_URL}/module/get`,//string 
        {
            data: {
                id: data.module,
            }
        },
        (data) => {
            let queryReturn = data.content[0].moduleUrl

            setQueryResult(`${process.env[queryReturn]}`)
            

            setLoading(false)
            setStateKey(uuid())
        },
        (error) => {
          console.log("error")
        }
      )    
  }

  const onEditButtonClick = (event, record) => {
    history.push(`${pathname}/edit/` + record.id + `?moduleId=${searchCriteria}`)
  }

  const onViewButtonClick = (event, record) => {
    history.push(`${pathname}/view/` + record.id + `?moduleId=${searchCriteria}`)
  }

  return (
    <Box>
    <Box mb={2}>
      <Card>
        <Box className='view-card-header'>{translatedTextsObject.sequenceTableTitle}</Box>
        <Grid container justify="center" className='view-card-content' container spacing={3}>
        <Grid item xs={12}>
          <CngViewForm
            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
            showNotification={showNotification}
            bodySection={
              <SearchForm.FormFields
                disabled={false}
                showNotification={showNotification}
                //onClick={onSearch}
              />
            }
            formikProps={{
              ...SearchForm.formikProps,
              onSubmit: onSearch
            }}
            toClientDataFormat={FormProperties.toClientDataFormat}
            toServerDataFormat={FormProperties.toServerDataFormat}
          />
          </Grid>
          </Grid> 
       </Card>
     </Box>
    <Card>
    {!loading ? (
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              //addRoute={`${pathname}/add`}
              addRoute={`${pathname}/add?moduleId=${searchCriteria}`}
              columns={columns}
              //del={{ url: SequenceApiUrls.DELETE }}
              del={{ url: `${queryResult}/sequence/hdr/delete`}}
              //editRoute={`${pathname}/edit`}
              //exportData={{ url: SequenceApiUrls.EXPORT }}
              exportData={{ url:`${queryResult}/sequence/hdr/download`}}
              //fetch={{ url: SequenceApiUrls.GET }}
              fetch={queryResult === "" ? { url: SequenceApiUrls.GET } : { url: `${queryResult}/sequence/hdr/get`}}
              idAccessor="id"
              notification={notification}
              //tableConfigurationCode="REPLACE_WITH_CORE_TABLE_CONFIG_CODE"
              //viewRoute={`${pathname}/view`}
              onEditButtonClick={onEditButtonClick}
              onViewButtonClick={onViewButtonClick}
              key={stateKey}
            />
          </Grid>
        </Grid>
      </CardContent>
      ) : null}
    </Card>
    </Box>
  )
}

export default TablePage
