import React from 'react';

const InterestSection = ({ data, refsArray }) => {
    return (
        <>
            {data.visibility.hasInterests &&
                <div ref={ref => refsArray.current[4] = ref} id="ourInterests" name="ourInterests" className="hv-section">
                    <div className="container-fluid">
                        <div className="row no-gutters w-100">
                            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-1 col-1"></div>
                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                                <section className="hv-section__inner-wrapper">
                                    <span className="hv-mobile-only w-100">
                                        <i className="section-icon icon-our-interests"></i>
                                    </span>
                                    <h1 className="section-primary-title">Our Interests</h1>
                                    <p className="section-primary-description">We are keen to explore opportunities in</p>

                                    <article className="section-article-primary">
                                        {data.interestedInCountryList.length > 0 &&
                                            <>
                                                <h1 className="section-secondary-title">
                                                    Countries
                                                </h1>
                                                <div className="pills-wrapper">
                                                    {data.interestedInCountryList.map((val, i) => {
                                                        return (
                                                            <label className="pills-item" key={i}>{val}</label>
                                                        );
                                                    })}

                                                </div>
                                            </>
                                        }

                                        {data.interestedInServiceList.length > 0 &&
                                            <>
                                                <h1 className="section-secondary-title">
                                                    Business Activities
                                                </h1>
                                                <div className="pills-wrapper">
                                                    {data.interestedInServiceList.map((val, i) => {
                                                        return (
                                                            <label className="pills-item" key={i}>{val}</label>
                                                        );
                                                    })}
                                                </div>
                                            </>
                                        }
                                        {data.interestedInIndustryList.length > 0 &&
                                            <>
                                                <h1 className="section-secondary-title">
                                                    Industries
                                                </h1>
                                                <div className="pills-wrapper">
                                                    {data.interestedInIndustryList.map((val, i) => {
                                                        return (
                                                            <label className="pills-item" key={i}>{val}</label>
                                                        );
                                                    })}
                                                </div>
                                            </>
                                        }

                                    </article>
                                </section>
                            </div>
                            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-1 col-1"></div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default InterestSection
