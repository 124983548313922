import { Box, Card, CardContent, Grid } from '@material-ui/core'
import React, { useEffect, useState }  from 'react'
import {
  components,
  constants,
  useServices,
  useTranslation
} from 'cng-web-lib'

import ClogsConfigApiUrls from 'src/apiUrls/ClogsConfigApiUrls'
import FormProperties from './FormProperties'
import LogisticsAdminTranslationText from '../LogisticsAdminTranslationText'
import SearchForm from '../SearchForm'
import { useHistory } from 'react-router-dom'
import {v4 as uuid} from 'uuid'

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    useDefaultNotification
  },
  form: { CngViewForm }
} = components

function TablePage(props) {
  const {
    location: { pathname },
    showNotification
  } = props
  
  const {fetchRecords} = useServices()
  const [searchCriteria, setSearchCriteria] = useState("")
  const [queryResult, setQueryResult] = useState("")
  const [stateKey, setStateKey] = useState(uuid())
  const [loading, setLoading] = useState(true)
  const history = useHistory()

  
  function onSearch(data) {
    setLoading(true)
    setSearchCriteria(data.module)

      fetchRecords.execute(
        `${process.env.REACT_APP_CLOG_SHARED_SERVICE_ORIGIN_URL}/module/get`,//string 
        {
            data: {
                id: data.module,
            }
        },
        (data) => {
            let queryReturn = data.content[0].moduleUrl
            
            setQueryResult(`${process.env[queryReturn]}`)
            
            console.log("queryresult....",queryResult)
            setLoading(false)
            setStateKey(uuid())
        },
        (error) => {
          console.log("errorrrrr")
        }
      )    
  }

  const notification = useDefaultNotification(showNotification)

  const translatedTextsObject = LogisticsAdminTranslationText()

  const columns = [
    {
      field: "configFor",
      title: translatedTextsObject.configFor,
    },
    {
      field: "configValue1",
      title: translatedTextsObject.configValue1,
    },
    {
      field: "configValue2",
      title: translatedTextsObject.configValue2,
    },
    {
      field: "configValue3",
      title: translatedTextsObject.configValue3,
    },
    {
      field: "configDescription",
      title: translatedTextsObject.configDescription,
    }
  ]

  const onEditButtonClick = (event, record) => {
    history.push(`${pathname}/edit/` + record.id + `?moduleId=${searchCriteria}`)
  }

  const onViewButtonClick = (event, record) => {
    history.push(`${pathname}/view/` + record.id + `?moduleId=${searchCriteria}`)
  }

  return (
    <Box>
    <Box mb={2}>
      <Card>
        <Box className='view-card-header'>{translatedTextsObject.clogsConfigTableTitle}</Box>
        <Grid container justify="center" className='view-card-content' container spacing={3}>
        <Grid item xs={12}>
          <CngViewForm
            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
            showNotification={showNotification}
            bodySection={
              <SearchForm.FormFields
                disabled={false}
                showNotification={showNotification}
                //onClick={onSearch}
              />
            }
            formikProps={{
              ...SearchForm.formikProps,
              onSubmit: onSearch
            }}
            toClientDataFormat={FormProperties.toClientDataFormat}
            toServerDataFormat={FormProperties.toServerDataFormat}
          />
          </Grid>
          </Grid> 
       </Card>
     </Box>
    <Card>
    {!loading ? (
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              addRoute={`${pathname}/add?moduleId=${searchCriteria}`} //frontend URL 
              columns={columns}
              del={{ url: `${queryResult}/config/hdr/delete`}}
              exportData={{ url:`${queryResult}/config/hdr/download`}}
              fetch={queryResult === "" ? { url: ClogsConfigApiUrls.GET } : { url: `${queryResult}/config/hdr/get`}}
              idAccessor="id"
              notification={notification}
              key={stateKey}
              onEditButtonClick={onEditButtonClick}
              onViewButtonClick={onViewButtonClick}
            />
          </Grid>
        </Grid>
      </CardContent>
      ) : null}
    </Card>
    </Box>
  )
}

export default TablePage
