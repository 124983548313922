import { Link } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import React from 'react';

const TABLET_SIZE = 768;
const Navbar = ({ data, executeScroll, mobileNavActive, theme, width, preview, previewClassName, isPartyAdmin, editProfileUrl }) => {
    const history = useHistory();
    const goToEditProfile = () => {
        if (editProfileUrl) {
            history.push(editProfileUrl);
        } else {
            history.goBack();
        }
    }
    return (
        <>
            <div id="page-action-mask"></div>

            <nav id='hv-primary-nav' className={`navbar hv-primary-navbar  hv-theme-${theme} ${width < TABLET_SIZE ? 'isMobile' : ''} ${mobileNavActive ? 'active' : ''} ${preview ? previewClassName : ''}`}>
                <div className="corporate-name-wrapper hv-mobile-only">
                    <h1 className="corporate-name">{data.partyName}</h1>
                </div>

                <div className="nav-list-wrapper">
                    <ul className="nav nav-pills">
                        <li className="nav-item">
                            <Link className="nav-link active js-smooth-scroll nav-icon nav-icon-home" onClick={() => executeScroll(0)}>
                                Home
                            </Link>
                        </li>
                        {data.visibility.hasIndustryServices &&
                            <li className="nav-item">
                                <Link className="nav-link js-smooth-scroll nav-icon nav-icon-industries-services" onClick={() => executeScroll(1)}>
                                    Industries & Services
                                </Link>
                            </li>
                        }
                        {data.visibility.hasAwards &&
                            <li className="nav-item">
                                <Link className="nav-link js-smooth-scroll nav-icon nav-icon-awards-accredit" onClick={() => executeScroll(2)}>
                                    Awards & Accreditations
                                </Link>
                            </li>
                        }
                        {data.visibility.hasAboutUs &&
                            <li className="nav-item">
                                <Link className="nav-link js-smooth-scroll nav-icon nav-icon-about-us" onClick={() => executeScroll(3)}>
                                    More About Us
                                </Link>
                            </li>
                        }
                        {data.visibility.hasInterests &&
                            <li className="nav-item">
                                <Link className="nav-link js-smooth-scroll nav-icon nav-icon-our-interest" onClick={() => executeScroll(4)}>
                                    Our Interests
                                </Link>
                            </li>
                        }
                        {data.visibility.hasContactUs &&
                            <li className="nav-item">
                                <Link className="nav-link js-smooth-scroll nav-icon nav-icon-contact-us" onClick={() => executeScroll(5)}>
                                    Contact Us
                                </Link>
                            </li>
                        }
                        <li className="nav-item">
                            <Link className="nav-link js-smooth-scroll nav-icon nav-icon-services-used" onClick={() => executeScroll(6)}>
                                GeTS Services Used
                            </Link>
                        </li>
                        {(isPartyAdmin || preview) &&
                            <li className="list-group hv-mobile-only">
                                <label className="list-group__header">Settings</label>
                                <ul>
                                    <li className="nav-item">
                                        <Link className="nav-link nav-icon nav-icon-edit-profile" onClick={() => goToEditProfile()}>
                                            Edit Profile
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        }
                    </ul>
                </div>
                <span className="app-copyright hv-mobile-only">
                    <label className="text-green font-14">
                        Powered by GeTS NextGen
                    </label>
                </span>
            </nav>
        </>
    )
}

export default Navbar
