import { Card, CardContent, Grid } from '@material-ui/core'
import {
  DateTimeFormatter,
  DataFlattener,
  components,
} from 'cng-web-lib'

import LogisticsAdminTranslationText from '../LogisticsAdminTranslationText'
import CustomUserAccountApiUrls from 'src/apiUrls/CustomUserAccountApiUrls'
import React from 'react'
import makeValidationSchema from './MakeValidationSchema'
import moment from 'moment'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngSwitchField,
      CngTextField,
      CngSelectField,
      CngDateField,
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  userId: "",
  partyId:"",
  validityStartDate:"",
  validityEndDate:"",
  active: false,
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {

  const translatedTextsObject = LogisticsAdminTranslationText()

	return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.customUserAccount} />
      <CardContent>
       <Grid container spacing={3}>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.userId}>
            <CngSelectField
              name="userId"
              label={translatedTextsObject.userId}
              disabled={disabled}
              fetch={{url: `${CustomUserAccountApiUrls.GET_USER}`, textAccessor: "loginId"}}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.validityStartDate}>
            <CngDateField
              name="validityStartDate"
              label={translatedTextsObject.validityStartDate}
              format={"YYYY-MM-DD"}
              disabled={disabled}
              shouldDisableDate={(date) => {
                    const today = moment().startOf('day');
                    return moment(date).isBefore(today);
                }}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.validityEndDate}>
            <CngDateField
              name="validityEndDate"
              label={translatedTextsObject.validityEndDate}
              format={"YYYY-MM-DD"}
              disabled={disabled}
              shouldDisableDate={(date) => {
                    const today = moment().startOf('day');
                    return moment(date).isBefore(today);
                }}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.active}>
            <CngSwitchField
              name="active"
              label={translatedTextsObject.activeUser}
              disabled={disabled}
            />
          </CngGridItem>
        </Grid>
      </CardContent>
    </Card>
	)
}

function toClientDataFormat(serverData) {
  let localData = DataFlattener.parse(serverData);

  localData.validityStartDate = DateTimeFormatter.toClientDate(
    localData.validityStartDate,
        'YYYY-MM-DD'
  );
  localData.validityEndDate = DateTimeFormatter.toClientDate(
    localData.validityEndDate,
        'YYYY-MM-DD'
  );
  return localData;
}

function toServerDataFormat(localData) {
  localData.validityStartDate = DateTimeFormatter.toServerDate(
    localData.validityStartDate,
        'YYYY-MM-DD'
  );
  localData.validityStartDate = DateTimeFormatter.toServerDate(
    localData.validityStartDate,
        'YYYY-MM-DD'
  );
  return DataFlattener.unflatten(localData);
}


const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
