import { useHistory } from 'react-router-dom';
import React from 'react';

const PublishBar = ({ setDisplayConfirmModal }) => {
    const history = useHistory();

    return (
        <>
            <span className="page-action-preview-border-bottom"></span>

            <header id="preview-cta-bar">
                <label className="text-white">Do you want to publish this content?</label>
                <button onClick={() => setDisplayConfirmModal(true)} name="btnPublish" id="btnPublish" className="btn btn-outline-primary hv-btn hv-btn-outline-primary">Publish</button>
                <button onClick={() => history.goBack()} name="btnCancel" id="btnCancel" className="btn btn-outline-secondary hv-btn hv-btn-outline-secondary">Cancel</button>
            </header>
        </>
    );
}
export default PublishBar


