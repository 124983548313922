import { CircularProgress, Grid } from '@material-ui/core';
import { components, constants, SendRequest } from 'cng-web-lib';
import React, { useRef, useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { useFormContext } from 'react-hook-form';
import CodeMaintainanceApiUrls from 'src/apiUrls/CodeMaintainanceApiUrls.js';
import pathMap from 'src/paths/pathMap';
import { CNG_BASE_URL, FIELDS_MAX_LENGTH, TEXT } from './ContactUsConstants';

const {
    AxiosMethod,
    filter: { EQUAL }
} = constants

const {
    CngGridItem,
    form: {
        adapter: {
            useFormAdapter: { useFormikContext }
        },
        field: { CngTextField, CngCountryAutocompleteField, CngLookupAutocompleteField },
    },
    button: { CngPrimaryButton }
} = components

const COUNTRY_CODE = "CountryCode";
const FormFields = ({ data, disabled }) => {
    const [submitContactFormLoading, setSubmitContactFormLoading] = useState(false);
    const [submitContactFormIsSuccess, setSubmitContactFormIsSuccess] = useState(false);
    const [showContactFormFeedback, setShowContactFormFeedback] = useState(false);
    const recaptchaRef = useRef();
    const { resetForm } = useFormikContext();
    const { handleSubmit } = useFormContext();

    const onSubmit = async (values) => {
        setSubmitContactFormLoading(true);

        const token = await recaptchaRef.current.executeAsync();
        const submittedValues = {
            micrositeContactUsDetails:
            {
                ...values,
                partyUserEmail: data.partyDetails.contactEmail,
                partyId: data.partyDetails.id
            },
            partyName: data.partyName,
            recaptchaValue: token,
            micrositePath: `${CNG_BASE_URL}${pathMap.PARTY_MICROSITE_VIEW_BASE}/${data.partyDetails.micrositePath}`,
            calistaSitePath: `${CNG_BASE_URL}`
        }
        values.partyId = data.partyDetails.id;
        values.partyName = data.partyName;

        const sendRequest = new SendRequest()
        sendRequest.execute(AxiosMethod.POST, `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/microsite/contact-us`,
            { ...submittedValues },
            () => {
                resetForm();
                setSubmitContactFormLoading(false);
                setSubmitContactFormIsSuccess(true);
                setShowContactFormFeedback(true);
                setTimeout(() => setShowContactFormFeedback(false), 5000);
            },
            () => {
                setSubmitContactFormLoading(false);
                setSubmitContactFormIsSuccess(false);
                setShowContactFormFeedback(true);
                setTimeout(() => setShowContactFormFeedback(false), 5000);
            }
        )
    }

    return (
        <>
            <Grid container spacing={3}>
                <CngGridItem xs={12} sm={6}>
                    <CngTextField
                        name='firstName'
                        label='First Name*'
                        className='hv-form-control'
                    />
                </CngGridItem>
                <CngGridItem xs={12} sm={6}>
                    <CngTextField
                        name='lastName'
                        label='Last Name*'
                        className='hv-form-control'

                    />
                </CngGridItem>
                <CngGridItem xs={12} sm={12}>
                    <CngTextField
                        name='requesterEmail'
                        label='Email*'
                        className='hv-form-control'
                    />
                </CngGridItem>
                <CngGridItem xs={12} sm={6}>
                    <CngLookupAutocompleteField
                        name='contactNumberCountryCode'
                        label={"Area Code"}
                        id="microsite-area-code"
                        lookupProps={{
                            url: CodeMaintainanceApiUrls.MAINTAINANCE_CODE,
                            customData: {
                                codeMType: COUNTRY_CODE
                            },
                            label: (record) => {
                                return `${record.descriptionEn} (+${record.callCode})`
                            },
                            value: 'code',
                            filters: [{
                                field: 'active',
                                operator: EQUAL,
                                value: true
                            }],
                            shouldEnableSessionCache: true
                        }}
                        className='hv-form-control'
                    />
                </CngGridItem>
                <CngGridItem xs={12} sm={6}>
                    <CngTextField
                        name="contactNumber"
                        label={"Contact Number"}
                        className='hv-form-control'
                    />
                </CngGridItem>
                <CngGridItem xs={12} sm={6}>
                    <CngTextField
                        name='company'
                        label='Company'
                        className='hv-form-control'
                    />
                </CngGridItem>
                <CngGridItem xs={12} sm={6}>
                    <CngCountryAutocompleteField
                        id="microsite-location-code"
                        name='companyCountryCode'
                        label='Location'
                        className='hv-form-control'
                    />
                </CngGridItem>
                <CngGridItem xs={12} sm={12}>
                    <CngTextField
                        name='subject'
                        label='Subject*'
                        className='hv-form-control'
                    />
                </CngGridItem>
                <CngGridItem xs={12} sm={12}>
                    <CngTextField
                        name='message'
                        label='Message*'
                        inputProps={{ maxLength: FIELDS_MAX_LENGTH.MESSAGE }}
                        helperText={`${TEXT.MAX_CHARACTERS} ${FIELDS_MAX_LENGTH.MESSAGE} ${TEXT.CHARACTERS}`}
                        multiline
                        rows={4}
                        className='hv-form-control'
                    />
                </CngGridItem>
            </Grid>
            <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={`${process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}`}
            />

            <Grid container spacing={3}>
                <Grid item>
                    <CngPrimaryButton disabled={disabled} type='submit'
                        onClick={handleSubmit(onSubmit)}
                    >Submit</CngPrimaryButton>
                    {submitContactFormLoading && <CircularProgress />}
                </Grid>
            </Grid>
            {showContactFormFeedback &&
                <div className="inline-overlay-wrapper form-info-wrapper white">
                    <div className={`message-wrapper ${submitContactFormIsSuccess ? '' : 'error'}`}>
                        <i className="icon icon-success"></i>
                        <label>
                            {submitContactFormIsSuccess ?
                                "Your enquiry has been submitted successfully. We will reply to you as soon as possible." :
                                "Something went wrong. Please try again."}
                        </label>
                    </div>
                </div>
            }
        </>

    );
}

export default FormFields;