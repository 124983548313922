import { BasePage as CngBasePage } from 'cng-web-lib'
import AdminTable from './AdminTable.js'
import React from 'react'
import TranslationText from 'src/views/nmot/shared/NmotTranslationText'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_Nmot'

function AdminTablePage(props) {
  const translatedTextsObject = TranslationText()
  const breadcrumbNameMap = useBreadcrumbNameMap()

  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.nmotAdmin}
      renderPage={(showSnackbar) => (
        <AdminTable
          showNotification={showSnackbar}
          {...props}
        />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

export { AdminTablePage }
