import axios from 'axios';
import React, { useEffect, useState } from 'react';
import MicrositeLayoutComponent from './components/MicrositeLayoutComponent';

const MicrositeDemo = () => {
    const [data, setData] = useState(null);

    useEffect(() => {
        axios(`${process.env.PUBLIC_URL}/static/microsite/demo.json`).then(body => {
            const responseData = body.data;
            const countriesWithNoAddress = responseData.presentInCountryList.filter(country => country !== responseData.addressCountry);
            responseData.countriesWithNoAddress = countriesWithNoAddress;
            responseData.countryAddressArr = [responseData.addressLine01, responseData.addressLine02, responseData.addressLine03, responseData.city, responseData.state];
            setData(responseData);
        }
        );
    }, [])

    return (
        <>
            {data &&
                <MicrositeLayoutComponent
                    data={data}
                    preview={false}
                    disabled={true}
                />
            }
        </>
    );
}
export default MicrositeDemo;
