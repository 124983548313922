import { Card, CardContent, Grid } from '@material-ui/core'
import {
  DataFlattener,
  components,
} from 'cng-web-lib'

import LogisticsAdminTranslationText from '../LogisticsAdminTranslationText'
import React from 'react'
import makeValidationSchema from './MakeValidationSchema'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngSwitchField,
      CngTextField,
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  active: false,
  containerIsoType: "",
  contHeight: "",
  contHeightDesc: "",
  contHeightValue: "",
  contSize: "",
  contType: "",
  contTypeDesc: "",
  haulioContSizeType: "",
  isoCode: "",
  containerCode:"",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  
  const translatedTextsObject = LogisticsAdminTranslationText()

	return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.containerType} />
      <CardContent>
        <Grid container spacing={3}>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.active}>
            <CngSwitchField
              name="active"
              label={translatedTextsObject.active}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.containerIsoType}>
            <CngTextField
              name="containerIsoType"
              label={translatedTextsObject.containerIsoType}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.contHeight}>
            <CngTextField
              name="contHeight"
              label={translatedTextsObject.contHeight}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.contHeightDesc}>
            <CngTextField
              name="contHeightDesc"
              label={translatedTextsObject.contHeightDesc}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.contHeightValue}>
            <CngTextField
              name="contHeightValue"
              label={translatedTextsObject.contHeightValue}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.contSize}>
            <CngTextField
              name="contSize"
              label={translatedTextsObject.contSize}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.contType}>
            <CngTextField
              name="contType"
              label={translatedTextsObject.contType}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.contTypeDesc}>
            <CngTextField
              name="contTypeDesc"
              label={translatedTextsObject.contTypeDesc}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.haulioContSizeType}>
            <CngTextField
              name="haulioContSizeType"
              label={translatedTextsObject.haulioContSizeType}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.isoCode}>
            <CngTextField
              name="isoCode"
              label={translatedTextsObject.isoCode}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.containerCode}>
            <CngTextField
              name="containerCode"
              label={translatedTextsObject.containerCode}
              disabled={disabled}
            />
          </CngGridItem>
        </Grid>
      </CardContent>
    </Card>
	)
}

function toClientDataFormat(serverData) {
  let localData = DataFlattener.parse(serverData);
  return localData;
}

function toServerDataFormat(localData) {
  return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
