import { Accordion, AccordionDetails, AccordionSummary, MenuItem, Select } from '@material-ui/core';
import React, { useState } from 'react';

const ThemeSettings = ({THEMES, theme, changeTheme}) => {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <div id="display-settings-bar">
            <Accordion className="hv-settings-accordion" expanded={isExpanded} onChange={() => setIsExpanded(!isExpanded)}>
                <AccordionSummary className="card-header">
                    <button className="btn btn-link icon icon-settings icon-toggle-arrow collapsed" type="button" data-toggle="collapse" data-target="#hv-theme-panel-body" aria-expanded="true" aria-controls="hv-theme-panel-body">
                        Theme Settings
                    </button>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="form-group row mb-0">
                        <label className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 col-form-label hv-form-label label-non-mandatory mb-0">
                            Theme
                        </label>
                        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8 pos-relative">
                            <Select
                                disableUnderline={true}
                                value={theme}
                                onChange={changeTheme}
                            >
                                {THEMES.map((value, i) => (
                                    <MenuItem value={value} key={i}>{value}</MenuItem>
                                ))}
                            </Select>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

export default ThemeSettings
