import { Box, Paper, Typography, makeStyles } from '@material-ui/core'
import { Download } from 'react-feather'
import React, { useCallback, useRef, useState, useEffect } from 'react'
import { components, useServices } from 'cng-web-lib'

import NmotTranslationText from 'src/views/nmot/shared/NmotTranslationText'
import NMoTApiUrls from 'src/apiUrls/NMoTApiUrls.js'
import TtfbTprUserApiUrls from 'src/apiUrls/TtfbTprUserApiUrls'
import CngBackdrop from 'src/views/vesselschedule/searchschedule/cngcomponent/CngBackDrop'
import DownloadLogDialog from './DownloadLogDialog.js'
import DisclaimerPromptDialog from 'src/views/nmot/shared/component/DisclaimerPromptDialog'

import moment from 'moment'

const {
  table: { CngCrudTable, useDefaultNotification, DateRangeFilter, useFetchCustomLookup }
} = components

const useStyles = makeStyles((theme) => ({
  div: {
    '& .MuiTable-root': {
      '& .MuiTableHead-root .MuiTableRow-head .MuiTableCell-head': {
        borderBottom: 'none',
        fontWeight: 'bold',
        '&:nth-child(1)': {
          width: '20% !important'
        },
        '&:nth-child(2)': {
          width: '25% !important'
        },
        '&:nth-child(3)': {
          width: '25% !important'
        },
        '&:nth-child(4)': {
          width: '5% !important'
        },
        '&:nth-child(n + 5):nth-last-child(n + 2)': {
          width: '15% !important'
        },
        '&:nth-last-child(1)': {
          width: '5% !important'
        }
      },
      '& .MuiTableRow-root[index]': {
        '&:nth-child(even)': {
          backgroundColor: '#fafbfc'
        },
        '& td[class*="MuiTableCell-root"]': {
          border: '1px solid #f1f4f9 !important',
          borderLeft: 'none !important',
          borderRight: 'none !important',
          '&:nth-child(1)': {
            borderLeft: '1px solid #f1f4f9 !important',
            borderRadius: '5px 0 0 5px !important',
            width: '20% !important'
          },
          '&:nth-child(2)': {
            width: '25% !important'
          },
          '&:nth-child(3)': {
            width: '25% !important'
          },
          '&:nth-child(4)': {
            width: '5% !important'
          },
          '&:nth-child(n + 5):nth-last-child(n + 2)': {
            width: '15% !important'
          },
          '&:nth-last-child(1)': {
            width: '5% !important',
            borderRight: '1px solid #f1f4f9 !important',
            borderRadius: '0 5px 5px 0 !important',
          }
        }
      }
    }
  },
  downloadIcon: {
    color: theme.palette.primary.main,
    width: theme.spacing(2),
    height: theme.spacing(2)
  }
}))

function ApiLogsTable(props) {
  const translatedTextsObject = NmotTranslationText()
  const classes = useStyles()
  const { showNotification } = props

  const notification = useDefaultNotification(showNotification)

  const tableRef = useRef()
  const cngTableRef = useRef()
  const darkRef = useRef('')
  const [loading, setLoading] = useState(true)
  const [userDetailList, setUserDetailList] = useState([])
  const { fetchRecords, securedSendRequest } = useServices()
  const [columns, setColumns] = useState([])
  const fetchCustomLookup = useFetchCustomLookup();
  const tableRefStateCallback = useCallback(() => {
    return tableRef.current?.state
  }, [])

  function renderDateColumn(date) {
    if (!date) {
      return null
    }

    return <span>{moment(date).format('DD/MM/YYYY HH:mm:ss')}</span>
  }



  const [isDownloadLogDialogOpen, setDownloadLogDialogOpen] = useState(false)

  function closeDownloadDialog() {
    setDownloadLogDialogOpen(false)
  }

  const [downloadRowData, setDownloadRowData] = useState({})

  const downloadRow = (data) => {
    setDownloadLogDialogOpen(true)
    setDownloadRowData(data)
  }

  useEffect(() => {
    // Load user login ids
    setLoading(true)
    securedSendRequest.execute(
      'GET',
      `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/user-preference/by-current-user`,
      {},
      (data) => {
        darkRef.current = data.data.theme
      },
      (error) => {
        console.log(error)
      },
      onComplete
    )

    function onComplete() {
      fetchAllUsername()
    }
  }, [])

  function fetchAllUsername() {
    let idArr = []
    fetchRecords.execute(
      TtfbTprUserApiUrls.GET_USER_DETAIL,
      {},
      (data) => {
        if (data.content.length > 0) {
          data.content.forEach((user) => {
            if ((user.status = 21)) {
              idArr.push({ userId: user.id, profileId: user.userProfileId })
            }
          })
          fetchUsernameByProfileId(idArr)
        }
      },
      (error) => {
        console.log(error)
        setLoading(false)
      }
    )
  }

  function fetchUsernameByProfileId(idArr) {
    let usersDetails = []
    fetchRecords.execute(
      TtfbTprUserApiUrls.GET,
      {},
      (data) => {
        console.log("user data", data)
        if (data.content && data.content.length > 0) {
          idArr.forEach((user) => {
            let userData = data.content.filter(
              (userDetail) => userDetail.id == user.profileId
            )
            usersDetails.push({
              userId: user.userId,
              loginId: userData[0].loginId
            })
          })
        }
        setUserDetailList(usersDetails)

        loadFilterForLoginId(usersDetails)
      },
      (error) => {
        setLoading(false)
      }
    )
  }

  function loadFilterForLoginId(usersDetails) {


    const columnsWithFilterValue = [
      {
        field: 'createdBy',
        title: translatedTextsObject.loginId,
        show: true,
        customLookup: () => {
          return fetchCustomLookup(
            NMoTApiUrls.API_LOG_GET_DISTINCT_USER,
            {}
            ,//this will fetch all the user login ids in the filter dropdwon. which need to be change. 
            //  id:  10005 
            'content',
            (loginId) => {
              let loginIdShow = usersDetails.filter((user) => user.userId == loginId.createdBy)
              let username = loginIdShow.length > 0 ? loginIdShow[0].loginId : 'Undefined'
              return username;
            },
            'createdBy',

            (error) => console.error(error)
          )
        },
        render: (rowData) => {
          let userData = usersDetails.filter((user) => user.userId == rowData.createdBy)
          let username = userData.length > 0 ? userData[0].loginId : 'Undefined'
          return <Box>{username}</Box>
        }
      },
      {
        field: 'fileContentFileName',
        title: translatedTextsObject.uploadedFile,
        show: true,
        render: (rowData) => {
          return <Box>{rowData.fileContentFileName}</Box>
        }
      },
      {
        field: 'responseFileName',
        title: translatedTextsObject.response,
        show: true,
        render: (rowData) => {
          return <Box>{rowData.responseFileName}</Box>
        }
      },
      {
        field: 'action',
        title: translatedTextsObject.action,
        show: true,
        render: (rowData) => {
          return <Box>{rowData.action}</Box>
        }
      },
      {
        field: 'createdDate',
        title: translatedTextsObject.createdDate,
        show: true,
        defaultSort: 'desc',
        render: (rowData) => {
          return renderDateColumn(rowData.createdDate)
        },
        type: 'date',
        filterComponent: DateRangeFilter
      }
    ]
    setColumns(columnsWithFilterValue)
    setLoading(false)
  }
  return (
    <Paper>
      {!loading ? (
        <Box
          m='8px'
          className={darkRef.current.trim() === 'LIGHT' ? classes.div : ''}
        >
          <CngCrudTable
            {...props}
            fieldLevel=''
            tableRef={(ref) => {
              if (ref === null) {
                return
              }

              tableRef.current = ref
            }}
            columns={columns}
            fetch={{
              url: NMoTApiUrls.API_LOG_GET
            }}
            notification={notification}
            options={{
              filtering: true,
              draggable: false
            }}
            localization={{
              header: {
                actions: 'Actions'
              }
            }}
            refreshButtonProps={{
              hidden: true
            }}
            actions={[
              {
                icon: () => <Download className={classes.downloadIcon} />,
                tooltip: translatedTextsObject.download,
                onClick: (event, rowData) => downloadRow(rowData)
              }
            ]}
            customToolbar={(toolbar) => {
              const page = tableRefStateCallback()?.currentPage || 0
              const pageSize = tableRefStateCallback()?.pageSize || 10
              const totalResult = tableRefStateCallback()?.data?.length || 0

              return (
                <Box display='flex' alignItems='center'>
                  <Box pl='8px'>
                    <Typography variant='body2'>{`Showing ${Math.min(
                      page * pageSize + 1,
                      totalResult
                    )}-${Math.min(
                      (page + 1) * pageSize,
                      totalResult
                    )} of ${totalResult}`}</Typography>
                  </Box>
                  <Box flexDirection='row-reverse' flexGrow={1}>
                    {toolbar}
                  </Box>
                </Box>
              )
            }}
            postFetch={(data) => {
              return data.map((d) => ({ ...d, id: d.id }))
            }}
            cngTableRef={cngTableRef}
          />
        </Box>
      ) : (
        <CngBackdrop loading={loading} />
      )}
      <DownloadLogDialog
        isDialogOpen={isDownloadLogDialogOpen}
        closeDialog={closeDownloadDialog}
        showNotification={showNotification}
        downloadRowData={downloadRowData}
        cngTableRef={cngTableRef}
      />
      <DisclaimerPromptDialog
        showNotification={showNotification}
      />
    </Paper>
  )
}

export default ApiLogsTable