import { constants, useTranslation } from 'cng-web-lib'

import ClogsConfigKeys from 'src/constants/locale/key/ClogsConfig'
import ContainerTypeKeys from 'src/constants/locale/key/ContainerType'
import JsonSchemaKeys from 'src/constants/locale/key/JsonSchema'
import ModuleKeys from 'src/constants/locale/key/Module'
import CustomUserAccountKeys from 'src/constants/locale/key/CustomUserAccount'
import Namespace from 'src/constants/locale/Namespace'
import React from 'react'
import SequenceKeys from 'src/constants/locale/key/Sequence'

const LogisticsAdminTranslationText = () => {
  const {
    locale: {
      key: { UiComponentKeys }
    }
  } = constants
  
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.CLOGS_CONFIG,
    Namespace.SEQUENCE,
    Namespace.JSON_SCHEMA,
    Namespace.CONTAINER_TYPE,
    Namespace.MODULE,
    Namespace.CUSTOM_USER_ACCOUNT
  ])

  let clogsAdmin = translate(
    Namespace.CLOGS_CONFIG,
    ClogsConfigKeys.CLOGS_ADMIN
  )

  let clogsConfig = translate(
    Namespace.CLOGS_CONFIG,
    ClogsConfigKeys.TITLE
  )
  let clogsConfigTableTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Table.TITLE,
    {
      nameTitleised: clogsConfig
    }
  )
  let configFor = translate(
    Namespace.CLOGS_CONFIG,
    ClogsConfigKeys.CONFIG_FOR
  )
  let configValue1 = translate(
    Namespace.CLOGS_CONFIG,
    ClogsConfigKeys.CONFIG_VALUE1
  )
  let configValue2 = translate(
    Namespace.CLOGS_CONFIG,
    ClogsConfigKeys.CONFIG_VALUE2
  )
  let configValue3 = translate(
    Namespace.CLOGS_CONFIG,
    ClogsConfigKeys.CONFIG_VALUE3
  )
  let configDescription = translate(
    Namespace.CLOGS_CONFIG,
    ClogsConfigKeys.CONFIG_DESCRIPTION
  )

  let sequence = translate(
    Namespace.SEQUENCE,
    SequenceKeys.TITLE
  )
  let sequenceTableTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Table.TITLE,
    {
      nameTitleised: sequence
    }
  )
  let name = translate(
    Namespace.SEQUENCE,
    SequenceKeys.NAME
  )
  let pattern = translate(
    Namespace.SEQUENCE,
    SequenceKeys.PATTERN
  )
  let lastKey = translate(
    Namespace.SEQUENCE,
    SequenceKeys.LAST_KEY
  )
  let minValue = translate(
    Namespace.SEQUENCE,
    SequenceKeys.MIN_VALUE
  )
  let maxValue = translate(
    Namespace.SEQUENCE,
    SequenceKeys.MAX_VALUE
  )
  let incrementBy = translate(
    Namespace.SEQUENCE,
    SequenceKeys.INCREMENT_BY
  )
  let cycle = translate(
    Namespace.SEQUENCE,
    SequenceKeys.CYCLE
  )
  let moduleName = translate(
    Namespace.SEQUENCE,
    SequenceKeys.MODULE_NAME
  )
  let jsonSchema = translate(
    Namespace.JSON_SCHEMA,
    JsonSchemaKeys.TITLE
  )
  let jsonSchemaTableTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Table.TITLE,
    {
      nameTitleised: jsonSchema
    }
  )
  let schemaName = translate(
    Namespace.JSON_SCHEMA,
    JsonSchemaKeys.SCHEMA_NAME
  )

  let containerType = translate(
    Namespace.CONTAINER_TYPE,
    ContainerTypeKeys.TITLE
  )
  let containerTypeTableTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Table.TITLE,
    {
      nameTitleised: containerType
    }
  )
  let active = translate(
    Namespace.CONTAINER_TYPE,
    ContainerTypeKeys.ACTIVE
  )
  let containerIsoType = translate(
    Namespace.CONTAINER_TYPE,
    ContainerTypeKeys.CONTAINER_ISO_TYPE
  )
  let contHeight = translate(
    Namespace.CONTAINER_TYPE,
    ContainerTypeKeys.CONT_HEIGHT
  )
  let contHeightDesc = translate(
    Namespace.CONTAINER_TYPE,
    ContainerTypeKeys.CONT_HEIGHT_DESC
  )
  let contHeightValue = translate(
    Namespace.CONTAINER_TYPE,
    ContainerTypeKeys.CONT_HEIGHT_VALUE
  )
  let contSize = translate(
    Namespace.CONTAINER_TYPE,
    ContainerTypeKeys.CONT_SIZE
  )
  let contType = translate(
    Namespace.CONTAINER_TYPE,
    ContainerTypeKeys.CONT_TYPE
  )
  let contTypeDesc = translate(
    Namespace.CONTAINER_TYPE,
    ContainerTypeKeys.CONT_TYPE_DESC
  )
  let haulioContSizeType = translate(
    Namespace.CONTAINER_TYPE,
    ContainerTypeKeys.HAULIO_CONT_SIZE_TYPE
  )
  let isoCode = translate(
    Namespace.CONTAINER_TYPE,
    ContainerTypeKeys.ISO_CODE
  )
  let containerCode = translate(
    Namespace.CONTAINER_TYPE,
    ContainerTypeKeys.CONTAINER_CODE
  )
  let module = translate(
    Namespace.MODULE,
    ModuleKeys.TITLE
  )
  let moduleNameLabel = translate(
    Namespace.MODULE,
    ModuleKeys.MODULE_NAME_LABEL
  )
  let moduleUrl = translate(
    Namespace.MODULE,
    ModuleKeys.MODULE_URL
  )
  let moduleTableTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Table.TITLE,
    {
      nameTitleised: module
    }
  )
  let customUserAccount = translate(
    Namespace.CUSTOM_USER_ACCOUNT,
    CustomUserAccountKeys.TITLE
  )
  let customUserAccountTableTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Table.TITLE,
    {
      nameTitleised: customUserAccount
    }
  )
  let activeUser = translate(
    Namespace.CUSTOM_USER_ACCOUNT,
    CustomUserAccountKeys.ACTIVE
  )
  let userId = translate(
    Namespace.CUSTOM_USER_ACCOUNT,
    CustomUserAccountKeys.USER_ID
  )
  let partyId = translate(
    Namespace.CUSTOM_USER_ACCOUNT,
    CustomUserAccountKeys.PARTY_ID
  )
  let validityStartDate = translate(
    Namespace.CUSTOM_USER_ACCOUNT,
    CustomUserAccountKeys.VALIDITY_START_DATE
  )
  let validityEndDate = translate(
    Namespace.CUSTOM_USER_ACCOUNT,
    CustomUserAccountKeys.VALIDITY_END_DATE
  )

  return {
    clogsAdmin,
    clogsConfig,
    clogsConfigTableTitle,
    configFor,
    configValue1,
    configValue2,
    configValue3,
    configDescription,
    sequence,
    sequenceTableTitle,
    name,
    pattern,
    lastKey,
    minValue,
    maxValue,
    incrementBy,
    cycle,
    moduleName,
    jsonSchema,
    jsonSchemaTableTitle,
    schemaName,
    containerType,
    containerTypeTableTitle,
    active,
    containerIsoType,
    contHeight,
    contHeightDesc,
    contHeightValue,
    contSize,
    contType,
    contTypeDesc,
    haulioContSizeType,
    isoCode,
    containerCode,
    module,
    moduleNameLabel,
    moduleUrl,
    moduleTableTitle,
    customUserAccount,
    customUserAccountTableTitle,
    activeUser,
    userId,
    partyId,
    validityStartDate,
    validityEndDate
  }
}

export default LogisticsAdminTranslationText
