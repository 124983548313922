import { Yup, components, useServices } from 'cng-web-lib'
import React, { useContext } from 'react'

import CancelButton from 'src/components/button/CancelButton.js'
import NmotTranslationText from 'src/views/nmot/shared/NmotTranslationText'
import ValidationMessageTranslationText from 'src/views/nmot/shared/ValidationMessageTranslationText'
import * as ValidationRegex from 'src/views/nmot/shared/ValidationRegex'
import AddNmotUserDialogContext from './AddNmotUserDialogContext.js'
import SubmitButton from 'src/components/button/SubmitButton.js'
import NMoTApiUrls from 'src/apiUrls/NMoTApiUrls.js'
import { Typography, Box } from '@material-ui/core'

const {
  form: {
    CngViewForm,
    field: { CngSelectField, CngTextField }
  },
  table: { useDefaultNotification }
} = components

function AddNmotUserDialogForm(props) {
  const translatedTextsObject = NmotTranslationText()
  const { createRecord } = useServices()
  const dialogContext = useContext(AddNmotUserDialogContext)
  const {
    closeDialog,
    showNotification,
    form: { isSubmitting, setSubmitting },
    cngTableRef,
    validUserList
  } = dialogContext
  const { success: showSuccessNotification, error: showErrorNotification } =
    useDefaultNotification(showNotification)

  function onSubmit(data) {
    function onSuccess() {
      setSubmitting(false)
      closeDialog()
      if (cngTableRef.current && cngTableRef.current.performRefresh) {
        cngTableRef.current.performRefresh()
      }
      showSuccessNotification(translatedTextsObject.addNmotUserSuccess)
    }

    function onError(error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.errorMessages &&
        error.response.data.errorMessages.length > 0
      ) {
        showErrorNotification(error.response.data.errorMessages)
      } else {
        showErrorNotification(error.message)
      }
      console.log('nmot-user-add error', error.message)
      setSubmitting(false)
    }

    setSubmitting(true)

    createRecord.execute(
      NMoTApiUrls.ADMIN_PUT,
      {
        ...data
      },
      onSuccess,
      onError
    )
  }

  const validationMessageTranslation = ValidationMessageTranslationText()

  const validationSchema = Yup.object({
    userId: Yup.string().required(validationMessageTranslation.required),
    nmotThirdParty: Yup.string()
      .required(validationMessageTranslation.required)
      .test(
        'nmotThirdParty',
        validationMessageTranslation.invalidEmojiMsg,
        (value) => ValidationRegex.emojiRegx.test(value)
      )
      .test(
        'nmotThirdParty',
        validationMessageTranslation.invalidStringWSpecialChar,
        (value) => ValidationRegex.specialCharNotAllowedRegx.test(value)
      )
      .test(
        'nmotThirdParty',
        validationMessageTranslation.invalidStringWspace,
        (value) => ValidationRegex.spaceNotAllowedRegex.test(value)
      )
  })

  return (
    <CngViewForm
      formikProps={{
        initialValues: {
          ...initialValues
        },
        validationSchema: validationSchema,
        onSubmit: onSubmit,
        validateOnChange: false
      }}
      renderBodySection={() => (
        <FormBody
          closeDialog={closeDialog}
          isSubmitting={isSubmitting}
          validUserList={validUserList}
        />
      )}
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
    />
  )
}

function FormBody({ closeDialog, isSubmitting, validUserList }) {
  const translatedTextsObject = NmotTranslationText()

  return (
    <Box m={1.5}>
      <Box mb={2}>
        <Typography variant='body2' gutterBottom>
          {translatedTextsObject.nmotUserDetails}
        </Typography>
      </Box>

      <Box my={1}>
        <CngSelectField
          name='userId'
          label={translatedTextsObject.loginId}
          items={validUserList}
          isRequired
        />
      </Box>

      <Box my={1}>
        <CngTextField
          type='text'
          name='nmotThirdParty'
          label={translatedTextsObject.thirdParty}
          isRequired
          inputProps={{ maxLength: 36 }}
        />
      </Box>

      <Box display='flex' flexDirection={'row-reverse'} mt={2}>
        <SubmitButton isSubmitting={isSubmitting}>
          {translatedTextsObject.add}
        </SubmitButton>
        <Box mr={1}>
          <CancelButton onClick={closeDialog} disabled={isSubmitting} />
        </Box>
      </Box>
    </Box>
  )
}

const initialValues = {
  userId: '',
  nmotThirdParty: ''
}

export default AddNmotUserDialogForm
