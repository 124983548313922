import React, { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import NmotTranslationText from 'src/views/nmot/shared/NmotTranslationText'
import NMoTApiUrls from 'src/apiUrls/NMoTApiUrls.js'
import { Card, CardContent, Grid, Box } from '@material-ui/core'
import { components, useServices, DateTimeFormatter } from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import moment from 'moment'
import CngBackdrop from 'src/views/vesselschedule/searchschedule/cngcomponent/CngBackDrop'
import SubmitButton from 'src/components/button/SubmitButton'
import pathMap from 'src/paths/PathMap_Nmot'
import DisclaimerPromptDialog from 'src/views/nmot/shared/component/DisclaimerPromptDialog'
import CommonUtils from 'src/views/common/utils/Utils'


const {
  card: { CngSimpleCardHeader },
  form: {
    field: { CngTextField, CngSelectField, CngDateTimeField },
    adapter: {
      useFormAdapter: { useFormikContext }
    }
  },
  button: { CngSecondaryButton },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  contNo: '',
  nmot: '',
  loCode: '',
  nextCarrier: '',
  blNo: '',
  goodDesc: '',
  pickupTime: '',
  version: 0
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ showNotification, isSubmitting, updatedRecord }) {
  const location = useLocation()
  const history = useHistory()
  const data = location.state
  const { fetchRecords } = useServices()
  const translatedTextsObject = NmotTranslationText()
  const cTranslatedTextsObject = CalistaUiComponentTranslationText()
  const [activeHdList, setActiveHdList] = useState([])
  const { setFieldValue } = useFormikContext()
  const [loading, setLoading] = useState(true)
  const [isEdit, setIsEdit] = useState(false)
  const [isView, setIsView] = useState(false)
  const [initialData, setInitialData] = useState({})

  // Cannot use CngCodeMasterAutocompleteField as code clashed with cal_transport_mode codes
  const [nmotList, setNmotList] = useState([
    { text: 'Barge', value: 'BARGE' },
    { text: 'Rail', value: 'RAIL' },
    { text: 'Truck', value: 'TRUCK' }
  ])

  useEffect(() => {
    if (data != null && data.nmot != null) {
      const dataArr = Object.entries(data.nmot)
      dataArr.forEach(([key, val]) => {
        if(key==='pickupTime'){
          val = CommonUtils.formatString(val, CommonUtils.UI_NMOT_FORMAT_DATE_NUMBER)
        }
        setFieldValue(key, val)
      })
      setInitialData(data.nmot)
    }

    if (data != null && data.isView != null && data.isView) {
      setIsView(true)
    } else if (data != null && data.isEdit != null && data.isEdit) {
      setIsEdit(true)
    }

    fetchRecords.execute(
      NMoTApiUrls.H_ACTIVE_DEST_GET,
      undefined,
      (data) => {
        let temp = []
        data.content.forEach((ctry) => {
          let item = {
            text: (
              <Grid container spacing={2} alignItems='center'>
                <Grid item xs={2} sm={2}>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      '/static/images/countries/' +
                      ctry.countryCode +
                      '.svg'
                    }
                  />
                </Grid>
                <Grid item xs={4} sm={4}>
                  <div>
                    {ctry.countryDescriptionEn +
                      ', ' +
                      ctry.place +
                      ' (' +
                      ctry.loCode +
                      ')'}
                  </div>
                </Grid>
              </Grid>
            ),
            value: ctry.loCode
          }
          temp.push(item)
        })
        setActiveHdList(temp)
        setLoading(false)
      },
      (error) => {
        console.log(error)
        setLoading(false)
      }
    )
  }, [])

  // For continuous updates in Edit Page
  useEffect(()=>{
    if(updatedRecord != null){
      console.log("enter")
     
      updatedRecord.pickupTime = CommonUtils.formatString(updatedRecord.pickupTime, CommonUtils.UI_NMOT_FORMAT_DATE_NUMBER)
     
      setInitialData(updatedRecord)
      setFieldValue('version', updatedRecord.version)
    }
  },[updatedRecord])

  function onReset() {
    let dataArr = {}

    if (initialData != null && isEdit) {
      dataArr = Object.entries(initialData)
    } else if (!isEdit) {
      dataArr = Object.entries(DEFAULT_INITIAL_VALUES)
    }

    if (dataArr.length > 0) {
      dataArr.forEach(([key, val]) => {
        if (val == null) {
          setFieldValue(key, '')
        } else {
          setFieldValue(key, val)
        }
      })
    }
  }

  function onBack() {
    history.push({
      pathname: pathMap.NMOT_DASHBOARD
    })
  }

  return (
    <Box>
      {!loading ? (
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card>
                <CngSimpleCardHeader
                  title={
                    isEdit
                      ? translatedTextsObject.nmotEditTitle
                      : isView
                      ? translatedTextsObject.nmotViewTitle
                      : translatedTextsObject.nmotAddTitle
                  }
                />
                <CardContent>
                  <Grid container spacing={3}>
                    <CngGridItem xs={12} sm={6}>
                      <CngTextField
                        name='contNo'
                        label={translatedTextsObject.contNo}
                        isRequired
                        disabled={isView}
                      />
                    </CngGridItem>

                    <CngGridItem xs={12} sm={6}>
                      <CngSelectField
                        name='nmot'
                        label={translatedTextsObject.nmot}
                        items={nmotList}
                        isRequired
                        disabled={isView}
                        SelectProps={{
                          classes: {
                            root: 'select-props'
                          },
                          MenuProps: {
                            MenuListProps: {
                              classes: {
                                root: 'menu-list'
                              }
                            },
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left'
                            },
                            getContentAnchorEl: null
                          }
                        }}
                      />
                    </CngGridItem>

                    <CngGridItem xs={12} sm={6}>
                      <CngSelectField
                        name='loCode'
                        label={translatedTextsObject.hinterlandDest}
                        disabled={isView}
                        items={activeHdList}
                        SelectProps={{
                          classes: {
                            root: 'select-props'
                          },
                          MenuProps: {
                            MenuListProps: {
                              classes: {
                                root: 'menu-list'
                              }
                            },
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left'
                            },
                            getContentAnchorEl: null
                          }
                        }}
                      />
                    </CngGridItem>

                    <CngGridItem xs={12} sm={6}>
                      <CngTextField
                        name='nextCarrier'
                        label={translatedTextsObject.nextCarrier}
                        disabled={isView}
                        multiline
                        inputProps={{ maxLength: 100 }}
                      />
                    </CngGridItem>

                    <CngGridItem xs={12} sm={6}>
                      <CngTextField
                        name='blNo'
                        label={translatedTextsObject.blNo}
                        disabled={isView}
                        multiline
                        inputProps={{ maxLength: 50 }}
                      />
                    </CngGridItem>

                    <CngGridItem xs={12} sm={6}>
                      <CngDateTimeField
                        name='pickupTime'
                        label={translatedTextsObject.pickupTime}
                        disabled={isView}
                        format= {CommonUtils.UI_NMOT_FORMAT_DATE_NUMBER}
                        defaultValue={DateTimeFormatter.toClientDate(
                          new Date()
                        )}
                        shouldDisableDate={(date) => {
                          return moment(date).isBefore(
                            moment(DateTimeFormatter.toClientDate(new Date()))
                          )
                        }}
                      />
                    </CngGridItem>

                    <CngGridItem xs={12} sm={6}>
                      <CngTextField
                        name='goodDesc'
                        label={translatedTextsObject.goodDesc}
                        disabled={isView}
                        multiline
                        rows={4}
                        inputProps={{ maxLength: 512 }}
                      />
                    </CngGridItem>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Box mt={2} ml={2}>
            <Grid
              container
              justify='flex-start'
              alignItems='center'
              spacing={2}
            >
              {(isEdit || isView) && (
                  <Box mr={1}>
                    <CngSecondaryButton onClick={onBack}>
                      {cTranslatedTextsObject.back}
                    </CngSecondaryButton>
                  </Box>
                )}
              {!isView && (
                  <Box mr={1}>
                    <SubmitButton isSubmitting={isSubmitting}>
                      {isEdit
                        ? cTranslatedTextsObject.update
                        : translatedTextsObject.add}
                    </SubmitButton>
                  </Box>
              )}
              {!isView && (
                  <Box>
                    <CngSecondaryButton onClick={onReset}>
                      {cTranslatedTextsObject.reset}
                    </CngSecondaryButton>
                  </Box>
              )}
            </Grid>
          </Box>
        </Box>
      ) : (
        <CngBackdrop loading={loading} />
      )}
      <DisclaimerPromptDialog
        showNotification={showNotification}
      />
    </Box>
  )
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default FormProperties
