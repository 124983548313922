import { Box, Paper, Typography, makeStyles } from '@material-ui/core'
import { Plus, Trash, Edit2 } from 'react-feather'
import React, { useCallback, useRef, useState, useEffect } from 'react'
import { components, useServices } from 'cng-web-lib'

import NmotTranslationText from 'src/views/nmot/shared/NmotTranslationText'
import AddNmotUserDialog from './AddNmotUserDialog.js'
import NMoTApiUrls from 'src/apiUrls/NMoTApiUrls.js'
import TtfbTprUserApiUrls from 'src/apiUrls/TtfbTprUserApiUrls'
import EditNmotUserDialog from './EditNmotUserDialog.js'
import DisclaimerPromptDialog from 'src/views/nmot/shared/component/DisclaimerPromptDialog'
import CngBackdrop from 'src/views/vesselschedule/searchschedule/cngcomponent/CngBackDrop'

const {
  table: { CngCrudTable, useDefaultNotification, useFetchCustomLookup }
} = components

const useStyles = makeStyles((theme) => ({
  div: {
    '& .MuiTable-root': {
      '& .MuiTableHead-root .MuiTableRow-head .MuiTableCell-head': {
        borderBottom: 'none',
        fontWeight: 'bold',
        '&:nth-child(1)': {
          width: '50% !important'
        },
        '&:nth-child(2)': {
          width: '45% !important'
        },
        '&:nth-last-child(1)': {
          width: '5% !important'
        }
      },
      '& .MuiTableRow-root[index]': {
        '&:nth-child(even)': {
          backgroundColor: '#fafbfc'
        },
        '& td[class*="MuiTableCell-root"]': {
          border: '1px solid #f1f4f9 !important',
          borderLeft: 'none !important',
          borderRight: 'none !important',
          '&:nth-child(1)': {
            borderLeft: '1px solid #f1f4f9 !important',
            borderRadius: '5px 0 0 5px !important',
            width: '50%  !important'
          },
          '&:nth-child(2)': {
            width: '45% !important'
          },
          '&:nth-last-child(1)': {
            width: '5% !important',
            borderRight: '1px solid #f1f4f9 !important',
            borderRadius: '0 5px 5px 0 !important'
          }
        }
      }
    }
  },
  trashIcon: {
    color: theme.palette.text.error,
    width: theme.spacing(2),
    height: theme.spacing(2)
  },
  editIcon: {
    color: theme.palette.primary.main,
    width: theme.spacing(2),
    height: theme.spacing(2)
  },
  addIcon: {
    color: theme.palette.primary.main,
    width: theme.spacing(3),
    height: theme.spacing(3)
  }
}))

function AdminTable(props) {
  const translatedTextsObject = NmotTranslationText()
  const classes = useStyles()
  const { showNotification } = props

  const notification = useDefaultNotification(showNotification)
  const fetchCustomLookup = useFetchCustomLookup();
  const tableRef = useRef()
  const cngTableRef = useRef()
  const darkRef = useRef('')
  const [loading, setLoading] = useState(true)
  const [validUserList, setValidUserList] = useState([])
  const { fetchRecords, securedSendRequest } = useServices()
  // const [userIds, setUserIds]= useState([]) 
  const [columns, setColumns] = useState([])
  const tableRefStateCallback = useCallback(() => {
    return tableRef.current?.state
  }, [])

  

  const [isAddNmotUserDialogOpen, setAddNmotUserDialogOpen] = useState(false)

  function closeDialog() {
    setAddNmotUserDialogOpen(false)
  }

  const [isEditNmotUserDialogOpen, setEditNmotUserDialogOpen] = useState(false)

  function closeEditDialog() {
    setEditNmotUserDialogOpen(false)
  }

  const [editRowData, setEditRowData] = useState({})

  const editRow = (data) => {
    setEditNmotUserDialogOpen(true)
    setEditRowData(data)
  }

  useEffect(() => {
    // Load user login ids where user has an NMoT role
   
    /**
     * 0. load the user preference for table settings
     * 1. load the role code which containers "NMOT"
     * 2. load the user detials which got those role code from step (1)
     * 
    **/
   
    setLoading(true)
    securedSendRequest.execute(
      'GET',
      `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/user-preference/by-current-user`,
      {},
      (data) => {
        darkRef.current = data.data.theme
      },
      (error) => {
        console.log(error)
      },
      onComplete
    )

    function onComplete() {
      fetchRoleIdByRole()
    }

  }, [])

  function fetchRoleIdByRole() {
    let roleCodes = ""

    fetchRecords.execute(
      `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/role/get`,
      {
        customData: {},
        filters: [
          {
            field: 'roleCode',
            operator: 'LIKE',
            value: "nmot"
          }
        ]
      },
      (data) => {
        console.log("fetchRoleIdByRole", data)
        if (data.content && data.content.length > 0) {
          data.content.forEach((role) => {
         
            roleCodes = roleCodes+ role.roleCode+","
          })
          
         
          roleCodes  = roleCodes.substring(0, roleCodes.length-1)
          fetchUsers(roleCodes)
        }
      },
      (error) => {
        console.log('fetchRoleIdByRole : ' + error)
        setLoading(false)
        setValidUserList([{ text: 'Error', value: null }])
      }
    )
  }

  function fetchUsers(roleCodes) {
    
    //fetch user with particular role codes
    let users = []
    let nmotUserIdOption = []
    let roleRequest = {
      "tprUserRoleDTOS": [
          {
            "code": roleCodes
          }
        ]
      }
    fetchRecords.execute(
    TtfbTprUserApiUrls.GET_USER_DETAIL_BY_ROLES,
    { customData: roleRequest },

      (data)=>{
        console.log("userdata", data)
        if (data.length > 0) {
          let uniqueUser=[ ] //as there will be multiple roles assigned to one user, need to make sure the add user dropdown only display unique user. 
          data.forEach((user)=>{
            users.push({"userId": user.id, "profileId": user.userProfileId})
           
            if(!uniqueUser.includes(user.id)){
              nmotUserIdOption.push({
                text: user.userProfile.loginId,
                value: user.id
              })
              
             uniqueUser.push(user.id)
            }
          })
          console.log("uniqueUSER", uniqueUser)
          setValidUserList(nmotUserIdOption)
 


          const columnsWithLookupValue = [
            {
              field: 'userId',
              title: translatedTextsObject.loginId,
              show: true,
              customLookup: () => {
                return fetchCustomLookup(
                    `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/user/detail/get`,
                    {
                     id:  uniqueUser 
                    },
                    'content',
                    'userProfile[loginId]',
                    'id',
                    (error) => console.error(error)
                )
            },
              render: (rowData) => {
                let userData = nmotUserIdOption.filter((user) => user.value == rowData.userId)
                let username = userData.length > 0 ? userData[0].text : 'Undefined'
                return <Box>{username}</Box>
              }
            },
            {
              field: 'nmotThirdParty',
              title: translatedTextsObject.thirdParty,
              show: true,
              render: (rowData) => {
                return <Box>{rowData.nmotThirdParty}</Box>
              }
            }
          ]

          setColumns(columnsWithLookupValue)
          setLoading(false)
        }
      },
      (error)=>{
        console.log(error)
        setLoading(false)
        setValidUserList([{ text: 'Error', value: null }])
      }
    )
  
  }


  return (
    <Paper>
      {!loading ? (
        <Box
          m='8px'
          className={darkRef.current.trim() === 'LIGHT' ? classes.div : ''}
        >
          <CngCrudTable
            {...props}
            fieldLevel=''
            tableRef={(ref) => {
              if (ref === null) {
                return
              }

              tableRef.current = ref
            }}
            columns={columns}
            del={{
              url: NMoTApiUrls.ADMIN_DELETE
            }}
            fetch={{
              url: NMoTApiUrls.ADMIN_GET
            }}
            notification={notification}
            options={{
              filtering: true,
              draggable: false
            }}
            localization={{
              header: {
                actions: 'Actions'
              }
            }}
            deleteButtonProps={{
              icon: ({ color, ...otherProps }) => (
                <Trash {...otherProps} className={classes.trashIcon} />
              ),
              tooltip: translatedTextsObject.remove
            }}
            refreshButtonProps={{
              hidden: true
            }}
            actions={[
              {
                icon: () => <Edit2 className={classes.editIcon} />,
                tooltip: translatedTextsObject.edit,
                onClick: (event, rowData) => editRow(rowData)
              },
              {
                icon: (props) => <Plus className={classes.addIcon} />,
                tooltip: translatedTextsObject.add,
                isFreeAction: true,
                onClick: (event, rowData) => setAddNmotUserDialogOpen(true)
              }
            ]}
            fetchMode='FULL'
            customToolbar={(toolbar) => {
              const page = tableRefStateCallback()?.currentPage || 0
              const pageSize = tableRefStateCallback()?.pageSize || 0
              const totalResult = tableRefStateCallback()?.data?.length || 0

              return (
                <Box display='flex' alignItems='center'>
                  <Box pl='8px'>
                    <Typography variant='body2'>{`Showing ${Math.min(
                      page * pageSize + 1,
                      totalResult
                    )}-${Math.min(
                      (page + 1) * pageSize,
                      totalResult
                    )} of ${totalResult}`}</Typography>
                  </Box>
                  <Box flexDirection='row-reverse' flexGrow={1}>
                    {toolbar}
                  </Box>
                </Box>
              )
            }}
            postFetch={(data) => {
              return data.map((d) => ({ ...d, id: d.id }))
            }}
            cngTableRef={cngTableRef}
          />
        </Box>
      ) : (
        <CngBackdrop loading={loading} />
      )}
      <AddNmotUserDialog
        isDialogOpen={isAddNmotUserDialogOpen}
        closeDialog={closeDialog}
        validUserList={validUserList}
        showNotification={showNotification}
        cngTableRef={cngTableRef}
      />
      <EditNmotUserDialog
        isDialogOpen={isEditNmotUserDialogOpen}
        closeDialog={closeEditDialog}
        validUserList={validUserList}
        rowData={{ ...editRowData }}
        showNotification={showNotification}
        cngTableRef={cngTableRef}
      />
      <DisclaimerPromptDialog
        showNotification={showNotification}
      />
    </Paper>
  )
}

export default AdminTable
