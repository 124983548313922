import React from 'react';

const DefaultCompanyLogo = `${process.env.PUBLIC_URL}/static/logo-gets.svg`;
const HomeSection = ({ data, refsArray }) => {
    return (
        <div ref={ref => refsArray.current[0] = ref} className="container-fluid">
            <div className="row no-gutters w-100 h-100">
                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-1 col-1"></div>
                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-10 col-10">
                    <section className="hv-section__inner-wrapper corporate-primary">
                        <img className='corporate-logo' src={data.partyLogo ? data.partyLogo : DefaultCompanyLogo} />
                        <h1 className="corporate-name">
                            {data.partyName}        </h1>
                        <h3 className="corporate-mis-vision" >
                            {data.partyDetails.partyVision}
                        </h3>
                        <ul className="corporate-presence">
                            {data.presentInCountryList.map((val, i) => {
                                return (
                                    <li key={i}> {val}
                                    </li>
                                );
                            })}
                        </ul>
                    </section>
                </div>
                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-1 col-1"></div>
            </div>
        </div>
    );
}
export default HomeSection
