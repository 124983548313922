import CustomUserAccountApiUrls from 'src/apiUrls/CustomUserAccountApiUrls'
import FormProperties from './FormProperties'
import { Grid } from '@material-ui/core'
import React from 'react'
import { components } from 'cng-web-lib'
import pathMap from 'src/paths/PathMap_LogisticsAdmin'

const {
  form: { CngAddForm }
} = components

function AddPage({ history, showNotification }) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngAddForm
          fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
          history={history}
          showNotification={showNotification}
          bodySection={
            <FormProperties.Fields
              disabled={false}
              showNotification={showNotification}
            />
          }
          formikProps={FormProperties.formikProps}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          create={{
            url: CustomUserAccountApiUrls.POST,
            successRedirect: pathMap.CUSTOM_USER_ACCOUNT_LIST_VIEW
          }}
        />
      </Grid>
    </Grid>
  )
}

export default AddPage
