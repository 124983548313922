import NMoTApiUrls from 'src/apiUrls/NMoTApiUrls'
import FormProperties from './FormProperties'
import NmotTranslationText from 'src/views/nmot/shared/NmotTranslationText'
import { Grid } from '@material-ui/core'
import React, { useState } from 'react'
import { components, useServices } from 'cng-web-lib'
import Utils from '../shared/Utils'

const {
  form: { CngViewForm },
  table: { useDefaultNotification }
} = components

function NmotEditPage({ history, showNotification }) {

  const translatedTextsObject = NmotTranslationText()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [updatedRecord, setUpdatedRecord] = useState(null)
  const { updateRecord } = useServices()

  const {
    success: showSuccessNotification,
    error: showErrorNotification
  } = useDefaultNotification(showNotification)

  function onSubmit(data) {
    Utils.convertDate(data)
    function onSuccess(response) {
      console.log('connection-request onSuccess', JSON.stringify(response))
      setIsSubmitting(false)
      setUpdatedRecord(response)
      showSuccessNotification(translatedTextsObject.updateNmotSuccess)
    }

    function onError(error) {
      console.log('connection-request error', error.message)

      if (
        error.response &&
        error.response.data &&
        error.response.data.errorMessages &&
        error.response.data.errorMessages.length > 0
      ) {
        showErrorNotification(error.response.data.errorMessages)
      } else {
        showErrorNotification(error.message)
      }

      setIsSubmitting(false)
    }

    setIsSubmitting(true)

    updateRecord.execute(
      NMoTApiUrls.NMOT_EDIT_UPDATE,
      { 
        contNo: data.contNo,
        blNo: data.blNo,
        loCode: data.loCode,
        nmot: data.nmot,
        nextCarrier: data.nextCarrier,
        goodDesc: data.goodDesc,
        pickupTime: data.pickupTime,
        id: data.id,
        version: data.version,
        archived: data.archived,
        bot:data.bot,
        commercialRelease: data.commercialRelease,
        customData: data.customData,
        customsRelease: data.customsRelease,
        destPortCode: data.destPortCode,
        dispatchTime: data.dispatchTime,
        gateOut: data.gateOut,
        onYardTerminal: data.onYardTerminal,
        overall: data.overall,
        recordCreatedDate: data.recordCreatedDate,
        refreshedDt: data.refreshedDt,
        status: data.status,
        statusUpdatedDate: data.statusUpdatedDate
      },
      onSuccess,
      onError
    )
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngViewForm
          fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
          history={history}
          showNotification={showNotification}
          bodySection={
            <FormProperties.Fields
              showNotification={showNotification}
              isSubmitting={isSubmitting}
              updatedRecord={updatedRecord}
            />
          }
          formikProps={{
            ...FormProperties.formikProps,
            onSubmit: onSubmit
          }}
        />
      </Grid>
    </Grid>
  )
}

export default NmotEditPage
