import React, {useEffect, useState} from 'react'
import { components, useServices } from 'cng-web-lib'
import { useLocation, useParams } from 'react-router-dom'

import FormProperties from './FormProperties'
import { Grid } from '@material-ui/core'

const {
  form: { CngViewForm }
} = components

function ViewPage({ showNotification }) {
  const { id } = useParams()
  const {fetchRecords} = useServices()
  const [queryResult, setQueryResult] = useState("")
  const [loading, setLoading] = useState(true)
  const location = useLocation()

  useEffect(() => {
    setLoading(true)
    const queryParams = new URLSearchParams(location.search)

    fetchRecords.execute(
      `${process.env.REACT_APP_CLOG_SHARED_SERVICE_ORIGIN_URL}/module/get`,//string 
      {
          data: {
              id: queryParams.get('moduleId'),
          }
      },
      (data) => {
          let queryReturn = data.content[0].moduleUrl

          setQueryResult(`${process.env[queryReturn]}`)

          setLoading(false)

      },
      (error) => {
        console.log("error")
      })
    },[])

  return (
    <Grid container spacing={3}>
      {!loading ? (
      <Grid item xs={12}>
        <CngViewForm
          fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
          showNotification={showNotification}
          bodySection={
            <FormProperties.Fields
              disabled={true}
              showNotification={showNotification}
            />
          }
          formikProps={FormProperties.formikProps}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          fetch={{
            id: id,
            url: `${queryResult}/sequence/hdr/get`
          }}
        />
      </Grid>
      ) : null}
    </Grid>
  )
}

export default ViewPage
