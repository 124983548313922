import { Card, CardContent, Grid } from '@material-ui/core'
import {
  DataFlattener,
  components,
} from 'cng-web-lib'

import LogisticsAdminTranslationText from '../LogisticsAdminTranslationText'
import React from 'react'
import makeValidationSchema from './MakeValidationSchema'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  configFor: "",
  configValue1: "",
  configValue2: "",
  configValue3: "",
  configDescription: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  const translatedTextsObject = LogisticsAdminTranslationText()

	return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.clogsConfig} />
      <CardContent>
        <Grid container spacing={3}>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.configFor}>
            <CngTextField
              name="configFor"
              label={translatedTextsObject.configFor}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.configValue1}>
            <CngTextField
              name="configValue1"
              label={translatedTextsObject.configValue1}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.configValue2}>
            <CngTextField
              name="configValue2"
              label={translatedTextsObject.configValue2}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.configValue3}>
            <CngTextField
              name="configValue3"
              label={translatedTextsObject.configValue3}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.configDescription}>
            <CngTextField
              name="configDescription"
              label={translatedTextsObject.configDescription}
              disabled={disabled}
            />
          </CngGridItem>
        </Grid>
      </CardContent>
    </Card>
	)
}

function toClientDataFormat(serverData) {
  let localData = DataFlattener.parse(serverData);
  return localData;
}

function toServerDataFormat(localData) {
  return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
