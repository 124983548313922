import FormProperties from './FormProperties'
import { Grid } from '@material-ui/core'
import React from 'react'
import { components } from 'cng-web-lib'

const {
  form: { CngViewForm },
  table: { useDefaultNotification }
} = components

function NmotViewPage({ history, showNotification }) {

  const {
    success: showSuccessNotification,
    error: showErrorNotification
  } = useDefaultNotification(showNotification)

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngViewForm
          fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
          history={history}
          showNotification={showNotification}
          bodySection={
            <FormProperties.Fields
              showNotification={showNotification}
              updatedRecord={null}
            />
          }
          formikProps={{
            ...FormProperties.formikProps,
          }}
        />
      </Grid>
    </Grid>
  )
}

export default NmotViewPage
