import { useState, useEffect } from 'react';
export default function useWindowScroll() {
    const hasWindow = typeof window !== 'undefined';

    const getPageYOffset = () => {
        return hasWindow ? window.pageYOffset : null;
    }
    const [pageYOffset, setWindowPageYOffset] = useState(getPageYOffset());
    useEffect(() => {
        if (hasWindow) {
            function handleScroll() {
                setWindowPageYOffset(getPageYOffset());
              }
        
              window.addEventListener('scroll', handleScroll);
        }
    }, [hasWindow])

    return pageYOffset;
}