import React from 'react';

const IndustryServiceSection = ({ data, refsArray }) => {
    return (
        <>
            {data.visibility.hasIndustryServices &&
                <div ref={ref => refsArray.current[1] = ref} id="industryAndServices" name="industryAndServices" className="hv-section">
                    <div className="container-fluid">
                        <div className="row no-gutters w-100">
                            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-1 col-1 d-md-block d-sm-none"></div>
                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                                <section className="hv-section__inner-wrapper">
                                    <article className="section-article-primary">
                                        <span className="hv-mobile-only w-100">
                                            <i className="section-icon icon-industry-services"></i>
                                        </span>
                                        {data.presentInIndustryList.length > 0 &&
                                            <>
                                                <h1 className="section-primary-title">
                                                    Industries
                                                </h1>
                                                <div className="pills-wrapper">
                                                    {data.presentInIndustryList.map((val, i) => {
                                                        return (
                                                            <label className="pills-item" key={i}>{val}</label>
                                                        );
                                                    })}
                                                </div>
                                            </>
                                        }
                                        {data.presentInServiceList.length > 0 &&
                                            <>
                                                <h1 className="section-primary-title">
                                                    Services
                                                </h1>
                                                <div className="pills-wrapper">
                                                    {data.presentInServiceList.map((val, i) => {
                                                        return (
                                                            <label className="pills-item" key={i}>{val}</label>
                                                        );
                                                    })}
                                                </div>
                                            </>
                                        }
                                    </article>
                                </section>
                            </div>
                            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-1 col-1 d-md-block d-sm-none"></div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default IndustryServiceSection
