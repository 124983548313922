import NMoTApiUrls from 'src/apiUrls/NMoTApiUrls'
import NmotTranslationText from 'src/views/nmot/shared/NmotTranslationText'
import FormProperties from './FormProperties'
import { Grid } from '@material-ui/core'
import React, { useState } from 'react'
import { components, useServices } from 'cng-web-lib'
import Utils from '../shared/Utils'

const {
  form: { CngViewForm },
  table: { useDefaultNotification }
} = components

function NmotAddPage({ history, showNotification }) {

  const translatedTextsObject = NmotTranslationText()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { createRecord } = useServices()

  const {
    success: showSuccessNotification,
    error: showErrorNotification
  } = useDefaultNotification(showNotification)

  function onSubmit(data) {
    
    Utils.convertDate(data)

    function onSuccess(response) {
      console.log('connection-request onSuccess', response)
      setIsSubmitting(false)
      showSuccessNotification(translatedTextsObject.addNmotSuccess)
    }

    function onError(error) {
      console.log('connection-request error', error.message)

      if (
        error.response &&
        error.response.data &&
        error.response.data.errorMessages &&
        error.response.data.errorMessages.length > 0
      ) {
        showErrorNotification(error.response.data.errorMessages)
      } else {
        showErrorNotification(error.message)
      }

      setIsSubmitting(false)
    }

    setIsSubmitting(true)

    createRecord.execute(
      NMoTApiUrls.NMOT_ADD_PUT_UPDATE,
      { ...data },
      onSuccess,
      onError
    )
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngViewForm
          fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
          history={history}
          showNotification={showNotification}
          bodySection={
            <FormProperties.Fields
              showNotification={showNotification}
              isSubmitting={isSubmitting}
              updatedRecord={null}
            />
          }
          formikProps={{
            ...FormProperties.formikProps,
            onSubmit: onSubmit,
          }}
        />
      </Grid>
    </Grid>
  )
}

export default NmotAddPage