import { HomeSection, IndustryServiceSection, AwardSection, MoreAboutUsSection, InterestSection, ContactUsSection, GetsServiceSection } from './sections';
import Navbar from './navbar/Navbar';
import React, { useEffect, useState, useRef } from 'react'
import useWindowDimensions from 'src/views/microsite/components/useWindowDimensions';
import useWindowScroll from 'src/views/microsite/components/useWindowScroll';
import { EditProfileDropdown, PublishBar, PublishModal, ThemeSettings, ChangeThemeDropdown } from './preview-components'
import pathMap from 'src/paths/pathMap';
import { Link } from 'react-router-dom'
import 'src/views/microsite/styles/microsite-layout.scss'
import 'src/views/microsite/styles/theme-default.scss'
import 'src/views/microsite/styles/theme-dark.scss'
import 'src/views/microsite/styles/theme-bright.scss'
import 'src/views/microsite/styles/theme-light.scss'
import 'src/views/microsite/styles/helpers.scss'

{/* NOTE: do not re-arrange the order of these css imports */ }

const THEMES = ["default", "dark", "light", "bright"];
const PREVIEW_CLASSNAME = 'mode-preview';

const MicrositeLayoutComponent = (props) => {
    const { data, preview, setDisplayConfirmModal, toggleDisplayConfirmModal,
        displayConfirmModal, saveThemeChanges, onConfirmPublish, disabled,
        isPartyAdmin, editProfileUrl } = props;
    const [mobileNavActive, setMobileNavActive] = useState(false);
    const [theme, setTheme] = useState(THEMES[0]);
    const pageYOffset = useWindowScroll();
    const { height, width } = useWindowDimensions();
    const refsArray = useRef([]);
    const isUserLoggedIn = localStorage.getItem('userPreference') != undefined;
    let loggedinUserHomePage = (JSON.parse(localStorage.getItem('userPreference')) || {}).homePage;
    loggedinUserHomePage = loggedinUserHomePage || `${process.env.REACT_APP_DEFAULT_USER_HOME_PAGE}`;
    const redirectHomePage = isUserLoggedIn ? loggedinUserHomePage : pathMap.HOME;
    const showEditProfileDropdown = preview || isPartyAdmin;

    // stop classes from affecting other pages
    useEffect(() => {
      document.body.classList.add('mcs-html');


      return () => {
        // cleanup on component unmount
        document.body.classList.remove('mcs-html');
      }
    }, []);
    useEffect(() => {
        const responseTheme = data.partyDetails.micrositeTheme;
        if (responseTheme != null && THEMES.includes(responseTheme.toLowerCase())) {
            setTheme(responseTheme);
        }
    }, [data.partyDetails.micrositeTheme])

    const executeScroll = (i) => {
        refsArray.current[i].scrollIntoView({ behavior: 'smooth' });
    }

    const toggleMobileNav = () => {
        setMobileNavActive(!mobileNavActive);
    }

    const changeTheme = (event) => {
        const themeValue = event.target.value;
        setTheme(themeValue);
        saveThemeChanges(themeValue);
    }

    return (
        <>
            <div id="hamburger-icon" className={`js-mobile-nav ${mobileNavActive ? 'open' : ''}  ${preview ? PREVIEW_CLASSNAME : ''}`} onClick={() => toggleMobileNav()}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <main className={`hv-app-body ${preview ? PREVIEW_CLASSNAME : ''} hv-theme-${theme}`} id="appBody" >
                <div id="home" name="home" className="hv-jumbotron-banner member-basic pos-relative" >
                    <div id="hv-header-copyright" className="container-fluid header-copyright">
                        <div className="row no-gutters w-100">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="header-copyright__wrappers app-toolbar-nav-list">
                                    <Link to={redirectHomePage} target="_blank" rel='noopener noreferrer' data-toggle="tooltip" data-placement="bottom"
                                        title="Back to Calista NextGen" className="link-icon-btn link-icon-32 icon-hive-logo nav-list-item"></Link>
                                        <EditProfileDropdown isDisplay={showEditProfileDropdown} editProfileUrl={editProfileUrl} />
                                        <ChangeThemeDropdown isDisplay={isPartyAdmin} THEMES={THEMES} theme={theme} changeTheme={(event) => changeTheme(event)} />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* HOME */}
                    <HomeSection data={data} refsArray={refsArray} />
                </div>

                {/* Industries and Services */}
                <IndustryServiceSection data={data} refsArray={refsArray} />

                {/* Awards and Accreditations */}
                <AwardSection data={data} refsArray={refsArray} />

                {/* More About Us */}
                <MoreAboutUsSection data={data} refsArray={refsArray} />

                {/* Our Interests */}
                <InterestSection data={data} refsArray={refsArray} />

                {/* Contact Us */}
                <ContactUsSection data={data} refsArray={refsArray} disabled={disabled} isUserLoggedIn={isUserLoggedIn} />

                {/* GeTS Services Used */}
                <GetsServiceSection refsArray={refsArray} />

                {/* <!-- [Footer Pane] --> */}
                <footer id="hv-app-footer">
                    <div className="row no-gutters w-100">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <ul className="hv-footer-links">
                                <li>&copy; 2020 Global eTrade Services</li>
                                <li>
                                    <Link to={redirectHomePage} className="hidden-link" target="_blank" rel='noopener noreferrer' data-toggle="tooltip" data-placement="bottom" title="Back to Calista NextGen">GeTS NextGen</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </footer>
                {preview &&
                    <>
                        <PublishBar setDisplayConfirmModal={setDisplayConfirmModal} />
                        <ThemeSettings THEMES={THEMES} theme={theme} changeTheme={(event) => changeTheme(event)} />
                    </>
                }

            </main>
            <div id={`app-brandbar`} className={`sticky-header ${preview ? PREVIEW_CLASSNAME : ''}`}></div>
                <PublishModal isDisplay={preview} displayConfirmModal={displayConfirmModal} toggleDisplayConfirmModal={toggleDisplayConfirmModal}
                    onConfirmPublish={() => onConfirmPublish(theme)} data={data} />
            {/* Navigation */}
            <Navbar data={data} executeScroll={executeScroll} theme={theme} width={width} mobileNavActive={mobileNavActive} preview={preview}
                previewClassName={PREVIEW_CLASSNAME} isPartyAdmin={isPartyAdmin} editProfileUrl={editProfileUrl} />
            {/* Top Button */}
            <div id="back-top">
                <span className={`action-go-to-top ${pageYOffset > height ? 'action-go-to-top-active' : 'd-none'}`} onClick={() => executeScroll(0)}>
                    <i className="icon icon-goto-top-arrow"></i>
                </span>
            </div>
        </>

    );
}

export default MicrositeLayoutComponent
