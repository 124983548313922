import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants,
  useTranslation
} from 'cng-web-lib'

import BillingAuditApiUrls from 'src/apiUrls/BillingAuditApiUrls'
import React from 'react'
import BillingAuditKeys from 'src/constants/locale/key/BillingAudit'
import Namespace from 'src/constants/locale/Namespace'

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    DateTimeRangeFilter: DateTimeRangeFilter,
    useDefaultNotification
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  }
} = constants

function TablePage(props) {
  const {
    location: { pathname },
    showNotification
  } = props

  const notification = useDefaultNotification(showNotification)
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.BILLING_AUDIT
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let billingAudit = translate(
      Namespace.BILLING_AUDIT,
      BillingAuditKeys.TITLE
    )
    let tableTitle = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Table.TITLE,
      {
        nameTitleised: billingAudit
      }
    )
    let auditTrailId = translate(
      Namespace.BILLING_AUDIT,
      BillingAuditKeys.AUDIT_TRAIL_ID
    )
    let auditModule = translate(
      Namespace.BILLING_AUDIT,
      BillingAuditKeys.AUDIT_MODULE
    )
    let auditDescription = translate(
      Namespace.BILLING_AUDIT,
      BillingAuditKeys.AUDIT_DESCRIPTION
    )
    let userId = translate(
      Namespace.BILLING_AUDIT,
      BillingAuditKeys.USER_ID
    )
    let auditDate = translate(
      Namespace.BILLING_AUDIT,
      BillingAuditKeys.AUDIT_DATE
    )
    let keyReferenceNo = translate(
      Namespace.BILLING_AUDIT,
      BillingAuditKeys.KEY_REFERENCE_NO
    )
    let functionCodes = translate(
      Namespace.BILLING_AUDIT,
      BillingAuditKeys.FUNCTION_CODES
    )
    let auditReferenceId = translate(
      Namespace.BILLING_AUDIT,
      BillingAuditKeys.AUDIT_REFERENCE_ID
    )
    let partyId = translate(
      Namespace.BILLING_AUDIT,
      BillingAuditKeys.PARTY_ID
    )

    return {
      billingAudit,
      tableTitle,
      auditTrailId,
      auditModule,
      auditDescription,
      userId,
      auditDate,
      keyReferenceNo,
      functionCodes,
      auditReferenceId,
      partyId
    }
  }

  const columns = [
    {
      field: "id",
      title: translatedTextsObject.auditTrailId,
      type: "numeric",
      defaultSort: 'desc'
    },
    {
      field: "auditModule",
      title: translatedTextsObject.auditModule,
    },
    {
      field: "auditDescription",
      title: translatedTextsObject.auditDescription,
    },
    {
      field: "userId",
      title: translatedTextsObject.userId,
      type: "numeric",
    },
    {
      field: "auditDate",
      title: translatedTextsObject.auditDate,
      type: 'datetime',
      filterComponent: DateTimeRangeFilter,
    },
    {
      field: "keyReferenceNo",
      title: translatedTextsObject.keyReferenceNo,
    },
    {
      field: "functionCodes",
      title: translatedTextsObject.functionCodes,
    },
    {
      field: "auditReferenceId",
      title: translatedTextsObject.auditReferenceId,
      type: "numeric",
    },
    {
      field: "partyId",
      title: translatedTextsObject.partyId,
      type: "numeric",
    }
  ]

  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.tableTitle} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              columns={columns}
              del={{ url: BillingAuditApiUrls.DELETE }}
              exportData={{ url: BillingAuditApiUrls.EXPORT }}
              fetch={{ url: BillingAuditApiUrls.GET }}
              idAccessor="id"
              notification={notification}
            
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default TablePage
