import { BasePage as CngBasePage } from 'cng-web-lib'
import NmotAddPage from './NmotAddPage.js'
import NmotEditPage from './NmotEditPage.js'
import NmotViewPage from './NmotViewPage.js'
import React from 'react'
import TranslationText from 'src/views/nmot/shared/NmotTranslationText'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_Nmot'

function WrappedAddPage(props) {
  const translatedTextsObject = TranslationText()
  const breadcrumbNameMap = useBreadcrumbNameMap()

  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.nmotAdd}
      renderPage={(showSnackbar) => (
        <NmotAddPage
          showNotification={showSnackbar}
          {...props}
        />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedEditPage(props) {
  const translatedTextsObject = TranslationText()
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.nmotEdit}
      renderPage={(showSnackbar) => (
        <NmotEditPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedViewPage(props) {
  const translatedTextsObject = TranslationText()
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.nmotView}
      renderPage={(showSnackbar) => (
        <NmotViewPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

export {
  WrappedAddPage as AddPage,
  WrappedEditPage as EditPage,
  WrappedViewPage as ViewPage
}
