import { Yup, components, useServices } from 'cng-web-lib'
import React, { useContext, useState } from 'react'

import CancelButton from 'src/components/button/CancelButton.js'
import SubmitButton from 'src/components/button/SubmitButton.js'
import NMoTApiUrls from 'src/apiUrls/NMoTApiUrls.js'
import NmotTranslationText from 'src/views/nmot/shared/NmotTranslationText'
import ValidationMessageTranslationText from 'src/views/nmot/shared/ValidationMessageTranslationText'
import * as ValidationRegex from 'src/views/nmot/shared/ValidationRegex'
import EditNmotUserDialogContext from './EditNmotUserDialogContext.js'
import { Typography, Box } from '@material-ui/core'

const {
  form: {
    CngViewForm,
    field: { CngSelectField, CngTextField }
  },
  table: { useDefaultNotification }
} = components

function EditDestDialogForm(props) {
  const translatedTextsObject = NmotTranslationText()
  const { updateRecord } = useServices()
  const dialogContext = useContext(EditNmotUserDialogContext)
  const {
    closeDialog,
    showNotification,
    rowData,
    form: { isSubmitting, setSubmitting },
    cngTableRef,
    validUserList
  } = dialogContext
  const { success: showSuccessNotification, error: showErrorNotification } =
    useDefaultNotification(showNotification)

  function onSubmit(data) {
    function onSuccess() {
      setSubmitting(false)
      closeDialog()
      if (cngTableRef.current && cngTableRef.current.performRefresh) {
        cngTableRef.current.performRefresh()
      }
      showSuccessNotification(translatedTextsObject.editNmotUserSuccess)
    }

    function onError(error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.errorMessages &&
        error.response.data.errorMessages.length > 0
      ) {
        showErrorNotification(error.response.data.errorMessages)
      } else {
        showErrorNotification(error.message)
      }
      console.log('nmot-user-edit error', error.message)
      setSubmitting(false)
    }

    setSubmitting(true)

    updateRecord.execute(
      NMoTApiUrls.ADMIN_UPDATE,
      {
        ...data
      },
      onSuccess,
      onError
    )
  }

  const validationMessageTranslation = ValidationMessageTranslationText()

  const validationSchema = Yup.object({
    userId: Yup.string().required(validationMessageTranslation.required),
    nmotThirdParty: Yup.string()
      .required(validationMessageTranslation.required)
      .test(
        'nmotThirdParty',
        validationMessageTranslation.invalidEmojiMsg,
        (value) => ValidationRegex.emojiRegx.test(value)
      )
      .test(
        'nmotThirdParty',
        validationMessageTranslation.invalidStringWSpecialChar,
        (value) => ValidationRegex.specialCharNotAllowedRegx.test(value)
      )
      .test(
        'nmotThirdParty',
        validationMessageTranslation.invalidStringWspace,
        (value) => ValidationRegex.spaceNotAllowedRegex.test(value)
      )
  })

  return (
    <CngViewForm
      formikProps={{
        initialValues: {
          id: rowData.id,
          version: rowData.version,
          userId: rowData.userId,
          nmotThirdParty: rowData.nmotThirdParty
        },
        validationSchema: validationSchema,
        onSubmit: onSubmit,
        validateOnChange: false
      }}
      renderBodySection={() => (
        <FormBody closeDialog={closeDialog} isSubmitting={isSubmitting} validUserList={validUserList}/>
      )}
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
    />
  )
}

function FormBody({ closeDialog, isSubmitting, validUserList }) {
  const translatedTextsObject = NmotTranslationText()

  return (
    <Box m={1.5}>
      <Box mb={2}>
        <Typography variant='body2' gutterBottom>
          {translatedTextsObject.nmotUserDetails}
        </Typography>
      </Box>
      <Box my={1}>
        <CngSelectField
          name='userId'
          label={translatedTextsObject.loginId}
          items={validUserList}
          disabled={true}
          isRequired
        />
      </Box>

      <Box my={1}>
        <CngTextField
          type='text'
          name='nmotThirdParty'
          label={translatedTextsObject.thirdParty}
          inputProps={{ maxLength: 36 }}
          isRequired
        />
      </Box>

      <Box display='flex' flexDirection={'row-reverse'} mt={2}>
        <SubmitButton isSubmitting={isSubmitting}>
          {translatedTextsObject.apply}
        </SubmitButton>
        <Box mr={1}>
          <CancelButton onClick={closeDialog} disabled={isSubmitting} />
        </Box>
      </Box>
    </Box>
  )
}

export default EditDestDialogForm
