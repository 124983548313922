import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants
} from 'cng-web-lib'

import LogisticsAdminTranslationText from '../LogisticsAdminTranslationText'
import ModuleApiUrls from 'src/apiUrls/ModuleApiUrls'
import React from 'react'

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    useDefaultNotification
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  }
} = constants

function TablePage(props) {
  const {
    location: { pathname },
    showNotification
  } = props

  const notification = useDefaultNotification(showNotification)

  const translatedTextsObject = LogisticsAdminTranslationText()

  const columns = [
    {
      field: "moduleName",
      title: translatedTextsObject.moduleNameLabel,
    },
    {
      field: "moduleUrl",
      title: translatedTextsObject.moduleUrl,
    }
  ]

  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.tableTitle} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              addRoute={`${pathname}/add`}
              columns={columns}
              del={{ url: ModuleApiUrls.DELETE }}
              editRoute={`${pathname}/edit`}
              exportData={{ url: ModuleApiUrls.EXPORT }}
              fetch={{ url: ModuleApiUrls.GET }}
              idAccessor="id"
              notification={notification}
              //tableConfigurationCode="REPLACE_WITH_CORE_TABLE_CONFIG_CODE"
              viewRoute={`${pathname}/view`}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default TablePage
