import React from 'react'

import CloseDialogIconButton from 'src/components/iconbutton/CloseDialogIconButton.js'
import CancelButton from 'src/components/button/CancelButton.js'
import NmotTranslationText from 'src/views/nmot/shared/NmotTranslationText'
import {
  Typography,
  Paper,
  Box,
  IconButton,
  Grid,
  Card,
  Tooltip
} from '@material-ui/core'
import { Download } from 'react-feather'
import { components } from 'cng-web-lib'

const {
  CngDialog,
  table: { useDefaultNotification }
} = components

function DownloadLogDialog({
  isDialogOpen,
  closeDialog,
  downloadRowData,
  showNotification,
  cngTableRef
}) {
  const { success: showSuccessNotification, error: showErrorNotification } =
    useDefaultNotification(showNotification)

  const translatedTextsObject = NmotTranslationText()

  function base64toBlob(base64Data) {
    let sliceSize = 1024
    let byteCharacters = atob(base64Data)
    let bytesLength = byteCharacters.length
    let slicesCount = Math.ceil(bytesLength / sliceSize)
    let byteArrays = new Array(slicesCount)

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      let begin = sliceIndex * sliceSize
      let end = Math.min(begin + sliceSize, bytesLength)

      let bytes = new Array(end - begin)
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0)
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes)
    }
    return byteArrays
  }

  const downloadUploadedFile = () => {
    let blob = new Blob(base64toBlob(downloadRowData.fileContentData), {
      type: 'application/vnd.ms-excel'
    })
    let link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = downloadRowData.fileContentFileName
    link.click()

    showSuccessNotification(translatedTextsObject.downloadFileSucess)
    closeDialog()
  }

  const downloadResponseFile = () => {
    let blob = new Blob(base64toBlob(downloadRowData.responseData), {
      type: 'text/plain'
    })
    let link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = downloadRowData.responseFileName
    link.click()

    showSuccessNotification(translatedTextsObject.downloadFileSucess)
    closeDialog()
  }

  return (
    <CngDialog
      customDialogContent={
        <Paper>
          <Box m={1.5}>
            <Box mb={2}>
              <Typography variant='subtitle2' gutterBottom>
                {translatedTextsObject.selectDownloadFile}
              </Typography>
            </Box>
            {downloadRowData.fileContentFileName && (
              <Box mb={1}>
                <Card
                  style={{
                    backgroundColor: '#fcfdff'
                  }}
                >
                  <Box m={2}>
                    <Grid
                      container
                      xs={12}
                      sm={12}
                      justify='space-between'
                      alignItems='center'
                      spacing={2}
                    >
                      <Grid item xs={11} sm={11}>
                        <Tooltip title={downloadRowData.fileContentFileName}>
                          <Typography
                            variant='body2'
                            gutterBottom
                            style={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis'
                            }}
                          >
                            {translatedTextsObject.downloadUploadFile}{' '}
                            <b>{downloadRowData.fileContentFileName}</b>
                          </Typography>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={1} sm={1}>
                        <IconButton
                          aria-label='download'
                          onClick={downloadUploadedFile}
                          style={{
                            color: '#5e81f4',
                            width: '35px',
                            height: '35px',
                            borderRadius: '20%'
                          }}
                        >
                          <Download />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Box>
                </Card>
              </Box>
            )}
            {downloadRowData.responseFileName && (
              <Card
                style={{
                  backgroundColor: '#fcfdff'
                }}
              >
                <Box m={2}>
                  <Grid
                    container
                    xs={12}
                    sm={12}
                    justify='space-between'
                    alignItems='center'
                    spacing={2}
                  >
                    <Grid item xs={11} sm={11}>
                      <Tooltip title={downloadRowData.responseFileName}>
                        <Typography
                          variant='body2'
                          gutterBottom
                          style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                          }}
                        >
                          {translatedTextsObject.downloadResponseFile}{' '}
                          <b>{downloadRowData.responseFileName}</b>
                        </Typography>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={1} sm={1}>
                      <IconButton
                        aria-label='download'
                        onClick={downloadResponseFile}
                        style={{
                          color: '#5e81f4',
                          width: '35px',
                          height: '35px',
                          borderRadius: '20%'
                        }}
                      >
                        <Download />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Box>
              </Card>
            )}
            <Box display='flex' flexDirection={'row-reverse'} mt={2}>
              <Box mr={1}>
                <CancelButton onClick={closeDialog} />
              </Box>
            </Box>
          </Box>
        </Paper>
      }
      dialogTitle={
        <>
          {translatedTextsObject.downloadApiLogFile}
          <CloseDialogIconButton onClick={closeDialog} />
        </>
      }
      open={isDialogOpen}
      fullWidth
      maxWidth='xs'
    />
  )
}

export default DownloadLogDialog
